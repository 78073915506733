import { Middleware } from "redux";
import { isPending, isFulfilled } from "@reduxjs/toolkit";
import { AppStateType } from "reducers";
import { AppRequestStatusManager } from "./RequestStatusManager/RequestStatusManager";
import { RequestStatus } from "./types";

export const requestStatusMiddleware: Middleware<{}, AppStateType> = () => (
  next
) => (action) => {
  const { meta } = action || {};
  const {
    requestStatusId,
    initial = false,
    isTakeLatest,
    /**
     * See global.d.ts for its use cases. 
     * tldr: If true, the current fulfilled request status will still be considered pending
     * So it relies on the next request status in the chain to provide the same requestStatusId
     */
    isChainingRequests,
  } = (meta?.arg || {}) as IMeta;

  let abortSignal: AbortController["signal"] | undefined;

  // Every request status id will have its own abort controller
  if (requestStatusId) {
    const currentRequestStatus = AppRequestStatusManager.getRequestStatus(
      requestStatusId
    );
    const isRequestPending = !!isPending(action);
    const isRequestFulfilled = !!isFulfilled(action);
    /* -------------------------------------------------------------------------- */
    let isLoading = Boolean(initial);
    let isRequesting = false;
    let isSuccess = false;
    let error = undefined;
    let isAborted = undefined;
    let abortController: AbortController | undefined;

    // Note that we are not handling race condition yet
    if (isRequestPending) {
      if (
        isTakeLatest &&
        currentRequestStatus &&
        (currentRequestStatus.isRequesting || currentRequestStatus.isLoading)
      ) {
        /**
         * ABORT the previous request with the request status
         * Then set isRequesting = true for this (latest) request
         */
        AppRequestStatusManager.abortRequest({ id: requestStatusId });
      }

      isRequesting = true;
    } else if (isRequestFulfilled) {
      /**
       * If `isChainingRequests = true`, other requests in the chain must have the same requestStatusId
       */
      if (!isChainingRequests) {
        isRequesting = false;
        isLoading = false;
        isSuccess = true;
        error = undefined;
      } else {
        isRequesting = true;
      }
    } else {
      // rejected
      isRequesting = false;
      isLoading = false;
      error = action.error;
    }

    // If abortController does not exist yet, create a new one
    abortController =
      AppRequestStatusManager.getRequestStatusAbortController({
        requestStatusId,
      }) ?? new AbortController();

    const nextRequestStatus: RequestStatus = {
      id: requestStatusId,
      isLoading,
      isRequesting,
      error,
      isSuccess,
      isAborted,
      abortController,
    };

    abortSignal = abortController.signal;
    AppRequestStatusManager.setRequestStatus(nextRequestStatus);
  }

  return next(action);
};
