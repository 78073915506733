import useSimpleMenu from "./useSimpleMenu";
import { Button } from "@material-ui/core";
import { SimpleMenuItem } from "types";
import { ItemFilter } from "FiltersModule/utils";

const useBulkActionsSelector = ({
  selectedItemsIds,
  menuItems,
  anchorElProps,
  containerElevation,
  menuMaxHeight,
  showSearchBar,
  itemFilter,
  className,
  idKey,
}: {
  selectedItemsIds: (number | string)[]; // Must be unique
  menuItems: (SimpleMenuItem | any)[]; // Todo fix any
  anchorElProps?: {
    isDisabled?: boolean;
    name?: string;

    renderCustomAnchorEl?: ({
      isDisabled,
      name,
    }: {
      isDisabled?: boolean;
      name?: string;
    }) => JSX.Element;
  };
  containerElevation?: number;
  menuMaxHeight?: number;
  showSearchBar?: boolean;
  itemFilter?: ItemFilter;
  className?: string;
  idKey?: string;
}) => {
  const { isDisabled, name, renderCustomAnchorEl } = anchorElProps || {};

  const renderAnchorEl = () => {
    return renderCustomAnchorEl ? (
      renderCustomAnchorEl({ isDisabled })
    ) : (
      <Button variant="outlined" color="primary" disabled={isDisabled}>
        {name || "Modify"} {selectedItemsIds.length || ""}
      </Button>
    );
  };

  const { renderSimpleMenu } = useSimpleMenu({
    renderAnchorEl,
    containerElevation,
    menuMaxHeight,
    showSearchBar,
    itemFilter,
    className,
    idKey,
  });

  const BulkActionsSelector = renderSimpleMenu({
    disabled: isDisabled ?? false,
    items: menuItems,
  });

  return {
    BulkActionsSelector,
  };
};

export default useBulkActionsSelector;
