import { memo } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  Chip,
  FormControl,
  FormHelperText,
  Tooltip,
  TooltipProps
} from "@material-ui/core";
import styled from "styled-components";
import { ItemWithOtherProps } from "types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export type SingleValuePickerV2Props = {
  items: (string | number)[];
  item: string | number;
  itemsHash: Record<string, ItemWithOtherProps>;
  onValueChange: (event: React.ChangeEvent<any>) => void;
  inputLabel?: string;
  inputName?: string;
  fullWidth?: boolean;
  paddingTop?: string;
  disabled?: boolean;
  style?: object;
  helperText?: string;
  className?: string;
  tooltip?: TooltipProps['title']
};

const SingleValuePickerV2 = ({
  items = [],
  item,
  itemsHash,
  onValueChange,
  inputName,
  inputLabel,
  fullWidth = false,
  paddingTop = "",
  style = {},
  disabled = false,
  helperText = "",
  className = "",
  tooltip = ''
}: SingleValuePickerV2Props) => {
  const { label } = itemsHash[item] || {};

  return (
    <Tooltip title={tooltip}>
      <Container
        style={{ width: fullWidth ? "100%" : "unset", paddingTop, ...style }}
        className={`single-value-picker-v2-container ${className}`}
      >
        <InputLabel shrink htmlFor="select-multiple-native">
          {inputLabel || ""}
        </InputLabel>
        <Select
          fullWidth={fullWidth}
          name={inputName}
          disabled={disabled}
          value={item}
          onChange={onValueChange}
          renderValue={() => <div key={label}>{label}</div>}
        >
          {items.map((curr) => {
            const { value, label } = itemsHash[curr] || {};

            return (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            );
          })}
        </Select>
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : <></>}
      </Container>
    </Tooltip>
  );
};

export default memo(SingleValuePickerV2);
