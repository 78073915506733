import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  REPORTS_INTEGRATION_DETAILS_PATH,
  MEMBER_TABLE_VIEWS,
  REPORTS_OVERALL_INTEGRATION_DETAILS_PATH,
  DEFAULT_MEMBER_TABLE_VIEW,
} from "@constants";
import { setTargetService } from "actionCreators";
import {
  getTeamProfileUrl,
  getPendingEntitiesUrl,
  getNetworkLogUrl,
  getSandboxesTableUrl,
  getOverallIntegrationUrl,
  getSuperAdminPageUrl,
  getEntityErrorPageUrl,
} from "utils/url";
import { getImpersonateToken, getTempToken } from "AuthModule/services";
import { selectAuthToken } from "selectors";
import * as Types from "types";
import {
  getIntegrationHealthDeepLinkUrl,
  getIntegrationDetailsDeepLinkUrl,
  getReportTeamsPageDeepLinkUrl,
} from "utils";
import { ValueOf } from "type-fest";
import ApiClientManager from "core/apiClient";
import qs from "qs";
import { EntityErrorPageUrlQueryParams } from "types";

/**
 * A hook that returns navigation actions
 */
const useAppNavigation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token: string | undefined = useSelector(selectAuthToken);

  const navigateToTeamProfile = (
    teamId: string | number,
    memberTableView: ValueOf<
      typeof MEMBER_TABLE_VIEWS
    > = DEFAULT_MEMBER_TABLE_VIEW
  ) => () =>
    history.push(getTeamProfileUrl({ teamId: +teamId, memberTableView }));

  const navigateToIntegrationDetailsPage = useCallback(
    (
      teamId: number | string,
      targetService: string,
      targetServiceId: number
    ) => () => {
      history.push(
        getIntegrationDetailsDeepLinkUrl(teamId, targetService, targetServiceId)
      );
    },
    [history]
  );

  const navigateToGeneralIntegrationPage = useCallback(
    (targetService?: string, interval?: string) => {
      if (targetService && interval) {
        const queryparam = qs.stringify({ targetService, interval });
        history.push(getOverallIntegrationUrl(queryparam));
      } else {
        history.push(getOverallIntegrationUrl());
      }
    },
    [history]
  );

  const navigateToPendingEntitiesPage = useCallback(
    (
      teamId: number,
      targetService: string,
      targetServiceId: number,
      dataTypes: string[],
      mappingStatuses: string[]
    ) => () => {
      dispatch(
        setTargetService({
          name: targetService,
          id: targetServiceId,
          pendingEntitiesDataTypes: dataTypes,
          pendingEntitiesMappingStatuses: mappingStatuses,
        })
      );
      history.push(
        getPendingEntitiesUrl({ targetServiceId: targetServiceId, teamId })
      );
    },
    [dispatch, history]
  );

  const navigateToNetworkLog = useCallback(
    (
      mosaic_team_id: number,
      requestId: string | null,
      sessionId: string
    ) => () => {
      window.open(
        getNetworkLogUrl({ mosaic_team_id, requestId, sessionId }),
        "_blank"
      );
    },
    []
  );

  const navigateToTeamsTable = (reportTableView?: string) => {
    history.push(getReportTeamsPageDeepLinkUrl({ reportTableView }));
  };

  const navigateToMosaicAppWithImpersonateToken = (
    accountId: string | number
  ) => async () => {
    try {
      const {
        data: { auth_token },
      } = await getImpersonateToken(
        accountId,
        {
          params: {
            skip_onboarding: true,
          },
        },
        token
      );
      const {
        data: { temp_token },
      } = await getTempToken(auth_token);

      const realmId = ApiClientManager.getRailsSandboxRealmId();

      const queryParams = temp_token
        ? qs.stringify({
            access_token: temp_token,
            realm_id: realmId,
          })
        : qs.stringify({
            impersonate: auth_token,
            realm_id: realmId,
          });

      const redirectToMosaicUrl = `${ApiClientManager.getWebappUrl()}/?${queryParams}`;
      window.open(redirectToMosaicUrl);
    } catch (e: any) {
      console.error(e);
    }
  };

  const navigateToIntegrationHealth = useCallback(
    ({
      targetServiceId,
      targetService,
      teamId,
    }: {
      targetServiceId: number | string;
      targetService: Types.TargetService;
      teamId: number;
    }) => {
      history.push(
        getIntegrationHealthDeepLinkUrl(teamId, targetService, targetServiceId)
      );
    },
    [history]
  );

  const navigateToSandboxesTable = useCallback(() => {
    history.push(getSandboxesTableUrl());
  }, [history]);

  const navigateToSuperAdminTable = useCallback(() => {
    history.push(getSuperAdminPageUrl());
  }, [history]);

  const navigateToEntityErrorsTable = useCallback(
    ({
      openInNewTab,
      targetServiceId,
      entityId,
    }: {
      openInNewTab: boolean;
    } & EntityErrorPageUrlQueryParams) => {
      const url = getEntityErrorPageUrl({
        targetServiceId,
        entityId,
      });

      if (openInNewTab) {
        window.open(url, "_blank");
      } else {
        history.push(url);
      }
    },
    [history]
  );

  return {
    navigateToTeamProfile,
    navigateToIntegrationDetailsPage,
    navigateToNetworkLog,
    navigateToPendingEntitiesPage,
    navigateToMosaicAppWithImpersonateToken,
    navigateToTeamsTable,
    navigateToIntegrationHealth,
    navigateToGeneralIntegrationPage,
    navigateToSandboxesTable,
    navigateToSuperAdminTable,
    navigateToEntityErrorsTable,
  };
};

export default useAppNavigation;
