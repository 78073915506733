import React, { useCallback, useEffect, useState } from "react";
import { useMount } from "react-use";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  DialogContent,
  InputAdornment,
  Divider,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { LinearBuffer } from "components/Progress";
import { useDispatch, useSelector } from "react-redux";
import { plusSettingButtonProps } from "../hooks/usePlusSettingsButton";
import {
  selectCreateSandboxData,
  selectAvailableDumpFilesData,
} from "SandboxModule/selectors";
import { CreateSandboxParams } from "SandboxModule/types";
import {
  createDumpFile,
  fetchAvailableDumpFiles,
  generateRealmId,
  createSandbox,
} from "SandboxModule/thunks";
import SimpleModal from "components/SimpleModal";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  IconButton,
  FormGroup,
  FormControlLabel,
  Chip,
  Checkbox,
} from "@material-ui/core";
import { useToggle } from "react-use";
import ListSelect from "components/ListSelect";
import { conditionallyRenderElement } from "utils";
import { TagContainer, ChipsContainer } from "SandboxModule/styles";
import EllipsisText from "components/EllipsisText";
import { KEYBOARD_KEYS, chipWidth } from "@constants";
import { checkInputError } from "SandboxModule/utils";
import ApiClientManager from "core/apiClient";
import useSandboxes, {
  SandboxIsoStateIds,
} from "SandboxModule/hooks/useSandboxes";
import { getShouldFetchAvailableDumpFiles } from "utils";

const DividerBox = styled(Box)`
  margin-left: -2rem;
  margin-top: 1rem;
  width: calc(100% + 55px);

  .divider {
    background-color: black;
  }
`;

const RegionDiv = styled(Box)`
  flex-wrap: wrap;
  margin-top: 1rem;
  .textfield {
    min-width: 12rem;
  }
  .label-text {
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-end;
    font-size: 1rem;
  }
`;

const initialErrorMessages = {
  teamNameError: "",
  tagInputError: "",
};

const standardTagsList = [
  {
    name: "self-serve",
    label: "Self-serve",
    value: "self-serve",
  },
  {
    name: "50+",
    label: "50+",
    value: "50+",
  },
  {
    name: "100+",
    label: "100+",
    value: "100+",
  },
];

const DividerDiv = () => {
  return (
    <DividerBox>
      <Divider className="divider" />
    </DividerBox>
  );
};

type NewSandboxInitialValues = Omit<CreateSandboxParams, "onSuccess">;

const newSandboxInitialValues = {
  realmId: "", //
  teamName: "",
  serviceName: "", //
  fileName: "", //
  region: "", //
  domain: "", //
  realmType:"Sandbox", // never change at the moment
  /* ---------------------------------Optional----------------------------------------- */
  port: 0,
  protocol: "",
  environment: "",
};

const newDumpFileInitialValues = {
  fileName: "",
};

const defaultRegion = "us-east-1";
const defaultDomain = "mosaicapp.com";

const standardTagsCheckboxState = {
  "50+": false,
  "100+": false,
  "self-serve": false,
};

const shouldFetchAvailableDumpFiles = getShouldFetchAvailableDumpFiles();

const useCreateSandboxModal = ({
  showCreateSandboxForm,
  handleClose,
}: plusSettingButtonProps) => {
  const dispatch = useDispatch();
  const { fetchSandboxes } = useSandboxes();
  const { isRequesting: isCreatingSandbox } = useSelector(
    selectCreateSandboxData
  );
  const { isRequesting: isFetchingDumpFiles, data: dumpFiles } = useSelector(
    selectAvailableDumpFilesData
  );
  const [
    newSandboxValues,
    setNewSandboxValues,
  ] = useState<NewSandboxInitialValues>(newSandboxInitialValues);
  const [error, setInputError] = useState(initialErrorMessages);
  const [standardTags, setStandardTags] = useState(standardTagsCheckboxState);
  const [tagsList, setTagsList] = useState<string[]>([]);
  const [tagInput, setTagInput] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagInput(event.target.value);
  };

  const updateTagList = (tag: string) => {
    const newTagsList = [...tagsList, tag];
    setTagsList(newTagsList);
  };

  const addTag = (tag: string) => (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    setInputError((prev) => ({
      ...prev,
      tagInputError: "",
    }));
    const trimmedTag = tag.trim();

    if (!trimmedTag) {
      setInputError((prev) => ({
        ...prev,
        tagInputError: "",
      }));
      return;
    }

    const inputError = checkInputError(trimmedTag);

    if (e.key === KEYBOARD_KEYS.ENTER && inputError) {
      setInputError((prev) => ({
        ...prev,
        tagInputError: inputError,
      }));
      return;
    }
    if (e.key === KEYBOARD_KEYS.ENTER && !inputError) {
      setInputError((prev) => ({
        ...prev,
        tagInputError: inputError,
      }));
      const lowercaseTag = trimmedTag.toLowerCase();
      if (tagsList?.indexOf(lowercaseTag) === -1) {
        updateTagList(lowercaseTag);
        setTagInput("");
      }
    }
  };

  useMount(() => {
    shouldFetchAvailableDumpFiles && dispatch(fetchAvailableDumpFiles({}));
  });

  const removeTag = (tagToRemove: string) => {
    const newList = tagsList.filter((item) => item !== tagToRemove);
    setStandardTags({ ...standardTags, [tagToRemove]: false });
    setTagsList(newList);
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<{ name: string; checked: boolean; value: string }>
  ) => {
    setStandardTags({
      ...standardTags,
      [event.target.name]: event.target.checked,
    });
    if (event.target.checked) {
      updateTagList(event.target.value);
    } else {
      removeTag(event.target.value);
    }
  };

  const onDelete = (tag: string) => {
    if (isCreatingSandbox) {
      return;
    }
    removeTag(tag);
  };

  const mostRecentFile = dumpFiles[dumpFiles.length - 1]?.value;
  useEffect(() => {
    setNewSandboxValues({
      ...newSandboxInitialValues,
      fileName: mostRecentFile,
    });
  }, [mostRecentFile]);

  const [newDumpFileValues, setNewDumpFileValues] = useState(
    newDumpFileInitialValues
  );
  const [isCreateNewSandboxFileModalOpen, toggleOpenModal] = useToggle(false);
  const [isAdvancedMode, toggleAdvanceMode] = useToggle(false);

  const closeCreateSandboxModal = useCallback(() => {
    handleClose(() => {
      setNewSandboxValues({
        ...newSandboxInitialValues,
        fileName: mostRecentFile,
      });
      setInputError({
        teamNameError: "",
        tagInputError: "",
      });
      setStandardTags(standardTagsCheckboxState);
      setTagsList([]);
      toggleAdvanceMode(false);
    });
  }, [handleClose, mostRecentFile, toggleAdvanceMode]);

  const handleSubmitCreateSandboxForm = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      const teamNameError = checkInputError(newSandboxValues.teamName);
      setInputError({
        ...error,
        teamNameError: teamNameError,
      });
      if (!teamNameError) {
        dispatch(
          generateRealmId({
            teamName: newSandboxValues.teamName,
            onFailure: () => {
              alert("Failed to generate realm. Please try again");
            },
            onSuccess: ({ response }) => {
              const realmId = response.data;
              if (realmId) {
                const params = {
                  realmId,
                  teamName: newSandboxValues.teamName,
                  fileName: newSandboxValues.fileName,
                  domain: defaultDomain,
                  region: defaultRegion, // Hard-coded - May need it to be dynamic in the future
                  tags: tagsList,
                  realmType: newSandboxValues.realmType,
                  tenantName: process.env.REACT_APP_UI_ENV,
                  onFailure: ({ error }: any) => {
                    alert(
                      `Failed to create new sandbox: ${newSandboxValues.realmId}. ${error.message}`
                    );
                  },
                  onSuccess: ({ response }: any) => {
                    // Update sandbox creation timer
                    fetchSandboxes(SandboxIsoStateIds.SandboxCreationTimer);
                    closeCreateSandboxModal();
                  },
                  // serviceName: "",
                  // region: "",
                  // domain: "",
                  // fileName: "",
                };

                alert(
                  `Initiating creating new sandbox: ${newSandboxValues.teamName}. It will take a while.`
                );
                dispatch(createSandbox(params));
                setTagsList([]);
              }
            },
          })
        );
      }
    },
    [newSandboxValues.teamName, newSandboxValues.fileName, newSandboxValues.realmType, newSandboxValues.realmId, error, dispatch, tagsList, fetchSandboxes, closeCreateSandboxModal]
  );

  const onSelectSandboxFile = (values: string[]) => {
    setNewSandboxValues((prev) => ({
      ...prev,
      fileName: values[0],
    }));
  };

  const onCreateSandboxFormChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setNewSandboxValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreateFile = () => {
    alert(
      `Initiating creating new dump file ${newDumpFileValues.fileName}. It will take a while.`
    );
    dispatch(
      createDumpFile({
        fileName: newDumpFileValues.fileName,
        onFailure: ({ error }: any) => {
          alert(`Failed to create dump file. ${error.message}`);
        },
      })
    );
    setNewDumpFileValues(newDumpFileInitialValues);
  };

  const refreshFileList = () => {
    dispatch(fetchAvailableDumpFiles({}));
  };

  const handleNewDumpFileInfoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, name } = event.target;
    setNewDumpFileValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Keep the selected values for next sandbox when the modal not showing
  // useEffect(() => {
  //   if (showCreateSandboxForm && !newSandboxValues.realmId) {
  //     handleGenerateRealmId();
  //   }
  // }, [handleGenerateRealmId, newSandboxValues.realmId, showCreateSandboxForm]);

  const CreateSandboxFileModal = isCreateNewSandboxFileModalOpen && (
    <SimpleModal
      open={isCreateNewSandboxFileModalOpen}
      onClose={toggleOpenModal}
      headerText="Create New Sandbox File"
      Body={
        <form>
          <Box mb={5}>
            <TextField
              onChange={handleNewDumpFileInfoChange}
              value={newDumpFileValues.fileName}
              label="File Name"
              name="fileName"
              type="text"
              fullWidth
              disabled={isCreatingSandbox}
              variant="outlined"
            />
          </Box>
        </form>
      }
      onConfirm={handleCreateFile}
      confirmButtonText="Create File"
      closeOnConfirm
    />
  );

  const CreateSandboxModal = showCreateSandboxForm && (
    <Dialog
      maxWidth={"xs"}
      fullWidth
      open={showCreateSandboxForm}
      onClose={closeCreateSandboxModal}
    >
      <DialogTitle id="form-dialog-title">Create Sandbox</DialogTitle>
      <DialogContent>
        <form>
          <TextField
            required
            onChange={onCreateSandboxFormChange}
            value={newSandboxValues.teamName}
            label="Team Name"
            name="teamName"
            type="text"
            fullWidth
            variant="outlined"
            helperText={error.teamNameError}
            error={!!error.teamNameError}
          />
          <DividerDiv />

          <FormControl component="fieldset" fullWidth>
            <Box component="h3">Add tags to this team</Box>
            <TagContainer>
              <div className="initial-tag-div">
                <FormGroup>
                  {standardTagsList.map((item) => (
                    <FormControlLabel
                      key={`${item.value}-${
                        standardTags[item.value as keyof typeof standardTags]
                      }`}
                      control={
                        <Checkbox
                          checked={
                            standardTags[
                              item.value as keyof typeof standardTags
                            ]
                          }
                          onChange={handleCheckboxChange}
                          name={item.name}
                          value={item.value}
                          disabled={isCreatingSandbox}
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </FormGroup>
              </div>
              <div className="add-tag-div">
                <TextField
                  value={tagInput}
                  onKeyDown={addTag(tagInput)}
                  helperText={
                    error?.tagInputError ? error.tagInputError : "Tags"
                  }
                  onChange={handleInputChange}
                  disabled={isCreatingSandbox}
                  error={!!error.tagInputError}
                />
                <ChipsContainer>
                  {tagsList.map((tag) => (
                    <EllipsisText text={tag} width={chipWidth}>
                      <Chip
                        className="chip"
                        color="primary"
                        label={tag}
                        onDelete={() => onDelete(tag)}
                        style={{ maxWidth: `calc(${chipWidth} - 10%)` }}
                      />
                    </EllipsisText>
                  ))}
                </ChipsContainer>
              </div>
              {/* ----------------------------------- for start date ---------------------------------- */}
              {/* <div className="start-date">
                <TextField
                  value={tagInput}
                  onKeyDown={addTag(tagInput)}
                  helperText="Start Date"
                  onChange={handleInputChange}
                  disabled={isCreatingTeam}
                  type="date"
                />
              </div> */}
            </TagContainer>
          </FormControl>

          {isAdvancedMode && (
            <>
              <Box justifyContent={"flex-end"} display="flex" mb={2}>
                <Button
                  disabled={isCreatingSandbox}
                  color="primary"
                  onClick={toggleOpenModal}
                >
                  New
                </Button>
                <IconButton
                  onClick={isFetchingDumpFiles ? undefined : refreshFileList}
                  color="primary"
                  size="small"
                  edge="end"
                  disabled={isCreatingSandbox}
                >
                  <RefreshIcon />
                </IconButton>
              </Box>
              {conditionallyRenderElement({
                isLoading: isFetchingDumpFiles,
                error: null,
                content: (
                  <ListSelect
                    name="fileName"
                    onChange={onSelectSandboxFile}
                    optionList={dumpFiles}
                    values={[newSandboxValues.fileName]}
                    required
                  />
                ),
              })}

              {/* coming soon */}
              {/* <Box justifyContent={"space-evenly"} display="flex" mt={5}>
                <Button
                  disabled={isCreatingSandbox}
                  variant="contained"
                  color="primary"
                >
                  Change Realm
                </Button>
              </Box>
              <RegionDiv alignContent={"center"} justifyContent={"space-evenly"} display="flex">
                <span className="label-text">Region</span>
                <div className="textfield">
                  <FormControl variant="outlined" className="textfield">
                    <TextField
                      id="region-select"
                      name="region"
                      select
                      label="Select Region"
                      value={newSandboxValues.region}
                      onChange={onCreateSandboxFormChange}
                      variant="outlined"
                    >
                      {regionList.map((region) => (
                        <MenuItem key={region} value={region}>
                          {region}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </div>
              </RegionDiv> */}
            </>
          )}

          <Box>{isCreatingSandbox ? <LinearBuffer /> : null}</Box>
          <DialogActions>
            <Button
              disabled={isCreatingSandbox}
              onClick={toggleAdvanceMode}
              color="primary"
            >
              {isAdvancedMode ? "Basic" : "Advanced"}
            </Button>
            <Button
              disabled={
                isCreatingSandbox || !!error.tagInputError
                // ||
                // !!error.teamNameError
              }
              color="primary"
              onClick={handleSubmitCreateSandboxForm}
            >
              Create Sandbox
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );

  return {
    CreateSandboxModal,
    CreateSandboxFileModal,
  };
};

export default useCreateSandboxModal;
