import { createAsyncThunk } from "@reduxjs/toolkit";
import {} from "@constants";
import {} from "utils";
import { AppStateType } from "reducers";
import {
  CreateSandboxParams,
  FetchAvailableDumpFilesParams,
  DeleteSandboxesParams,
  FetchSandboxParams,
  CreateDumpFileParams,
  GenerateRealmIdParams,
  InitializeSandboxParams,
  FetchSandboxMembersCsv,
  UpdateSandboxParams,
  FetchSandboxCreatorsParams,
  FetchSandboxSuccessResponse,
} from "SandboxModule/types";
import * as SandboxServices from "SandboxModule/services";
import omit from "lodash/omit";
import { User } from "AuthModule/reducers";

export const createSandbox = createAsyncThunk(
  "createSandbox",
  async (
    params: CreateSandboxParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const { onFailure, onSuccess } = params;

    const {
      authReducer: { user },
    } = getState() as AppStateType;

    const auth_token = user?.auth_token;
    try {
      const data = {
        ...omit(params, "onSuccess"),
        meta: user?.account as User["account"],
      };
      const response = await SandboxServices.createSandbox(
        params,
        data,
        {},
        auth_token
      );

      onSuccess && onSuccess({ response });
      return response;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);

export const deleteSandboxes = createAsyncThunk(
  "deleteSandboxes",
  async (
    params: DeleteSandboxesParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      authReducer: { user },
    } = getState() as AppStateType;
    const auth_token = user?.auth_token;
    const { realmIds, onSuccess, onFailure } = params;
    const meta = user?.account as User["account"];

    try {
      const promises = realmIds.map((id) =>
        SandboxServices.deleteSandbox(
          params,
          { realmId: id, meta },
          {},
          auth_token
        )
      );
      const response = await Promise.allSettled(promises);

      onSuccess && onSuccess({ response });
      return response;
    } catch (e) {
      onFailure && onFailure({ error: e });
      return rejectWithValue(e);
    }
  }
);

export const fetchSandboxes = createAsyncThunk<
  FetchSandboxSuccessResponse,
  FetchSandboxParams
>(
  "fetchSandboxes",
  async (
    {
      includeArchived,
      createdAtStartDate,
      createdAtEndDate,
      realmIdSearchKey,
      teamNameSearchKey,
      statuses,
      creators,
      tags,
      isoStateId,
      realmTypes,
    }: FetchSandboxParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      authReducer: { user },
    } = getState() as AppStateType;
    const auth_token = user?.auth_token;

    const config = {
      params: {
        includeArchived,
        createdAtStartDate,
        createdAtEndDate,
        realmIdSearchKey,
        teamNameSearchKey,
        statuses,
        creators,
        tags,
        realmTypes,
      },
    };

    try {
      const response = await SandboxServices.fetchSandboxes(
        {},
        config,
        auth_token as string
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
export const fetchAvailableDumpFiles = createAsyncThunk(
  "fetchAvailableDumpFiles",
  async (
    params: FetchAvailableDumpFilesParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await SandboxServices.getAvailableDumpFiles(
        {},
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createDumpFile = createAsyncThunk(
  "createDumpFile",
  async (
    { onSuccess, fileName, onFailure }: CreateDumpFileParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      authReducer: { user },
    } = getState() as AppStateType;
    const auth_token = user?.auth_token;

    try {
      const data = {
        fileName,
      };
      const response = await SandboxServices.createDumpFile(
        {},
        data,
        {},
        auth_token
      );

      onSuccess && onSuccess({ response });
      return response.data;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);

export const generateRealmId = createAsyncThunk(
  "generateRealmId",
  async (
    { onFailure, onSuccess, teamName }: GenerateRealmIdParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await SandboxServices.generateRealmId(
        { teamName },
        {},
        auth_token
      );

      onSuccess && onSuccess({ response });
      return response;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);

export const initializeSandbox = createAsyncThunk(
  "initializeSandbox",
  async (
    { onFailure, onSuccess, realmId }: InitializeSandboxParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await SandboxServices.initializeSandbox(
        {},
        { realmId },
        {},
        auth_token
      );

      onSuccess && onSuccess({ response });
      return response.data;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);

export const fetchSandboxMembersCsv = createAsyncThunk(
  "fetchSandboxMembersCsv",
  async (
    { onFailure, onSuccess, teamId }: FetchSandboxMembersCsv,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await SandboxServices.getSandboxMembersCsvUrl(
        {},
        { team_id: teamId },
        {},
        auth_token
      );

      onSuccess && onSuccess({ response });
      return response.data;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);

export const updateSandbox = createAsyncThunk(
  "updateSandbox",
  async (
    { onFailure, onSuccess, realmId, teamName, tags }: UpdateSandboxParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await SandboxServices.updateSandbox(
        {},
        { realmId, teamName, tags },
        {},
        auth_token
      );

      onSuccess && onSuccess({ response });
      return response;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);

// Probably need to put in another module though
// export const replaceMembers = createAsyncThunk(
//   "replaceMembers",
//   async (params, { dispatch, getState, rejectWithValue }) => {
//     try {
//       const {
//         authReducer: { user },
//       } = getState() as AppStateType;
//       const auth_token = user?.auth_token;
//       const response = {};
//       return response;
//     } catch (e) {
//       return rejectWithValue(e);
//     }
//   }
// );

export const fetchSandboxCreators = createAsyncThunk(
  "fetchSandboxCreators",
  async (
    { onFailure, onSuccess }: FetchSandboxCreatorsParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await SandboxServices.getSandboxCreators(
        {},
        {},
        auth_token
      );
      onSuccess && onSuccess({ response });
      return response;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);
