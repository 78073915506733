import Modal from "shared/Modal";
import styled from "styled-components";
import { Button, Box, TextField } from "@material-ui/core";
import { ModalBaseProps } from "types";

type ModalProps = ModalBaseProps & {
  onConfirm?: () => void;
  headerText?: string | React.ReactNode;
  Body?: React.ReactNode;
  cancelButtonText?: string;
  confirmButtonText?: string;
  closeOnConfirm?: boolean;
  onCancelCallback?: () => void;
  onConfirmButtonClassNames?: string;
  hideConfirmButton?: boolean;
  disableConfirmButton?: boolean;
  disableCancelButton?: boolean;
  height?:number;
  width?:number;
};

const defaultHeaderText = "Change headerText props to replace this text";

const defaultCancelText = "Cancel";

const defaultConfirmText = "Confirm";

const SimpleModal = ({
  open,
  onClose,
  headerText,
  Body,
  cancelButtonText,
  confirmButtonText,
  onConfirm,
  closeOnConfirm,
  onCancelCallback,
  onConfirmButtonClassNames = "",
  hideConfirmButton = false,
  disableConfirmButton = false,
  disableCancelButton = false,
  height,
  width,
}: ModalProps) => {
  const onCancel = () => {
    onClose();

    onCancelCallback && onCancelCallback();
  };

  const onConfirmButtonClick = () => {
    onConfirm && onConfirm();

    if (closeOnConfirm) {
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose} modalHeight={height} modalWidth={width}>
      <Box>
        <Box component={"h3"}>{headerText ?? ""}</Box>
        {Body ?? null}
        <Box textAlign={"right"}>
          <StyledButton onClick={onCancel} disabled={disableCancelButton}>
            {cancelButtonText ?? defaultCancelText}
          </StyledButton>
          {!hideConfirmButton ? (
            <StyledButton
              className={onConfirmButtonClassNames}
              onClick={onConfirmButtonClick}
              color="primary"
              disabled={disableConfirmButton}
            >
              {confirmButtonText ?? defaultConfirmText}
            </StyledButton>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default SimpleModal;

const StyledButton = styled(Button)`
  &.red-warning-button {
    color: white;
    background-color: red;
  }

  &.blue-warning-button {
    color: white;
    background-color: #376fd0;
  }

  &.red-warning-text {
    color: red;
  }
`;
