import { RealmTypes } from "RealmModule/constants/realm";
import { Sandbox } from "types";
import pick from "lodash/pick";
import { ValueOf } from "type-fest";

export const DEFAULT_RAILS_REALM_ID = process.env.REACT_APP_UI_ENV || "";

export enum SandboxStatusTypes {
  Creating = "Creating",
  NotInitialized = "Not Initialized",
  Initialized = "Initialized",
  Failed = "Failed",
  Archived = "Archived",
  PartiallyRemoved = "Partially Removed",
}

export type SandboxStatusValues = ValueOf<typeof SandboxStatusTypes>;

export const isParty = process.env.REACT_APP_UI_ENV === "party";
export const isProd = process.env.REACT_APP_UI_ENV === "prod";
export const isDemo = process.env.REACT_APP_UI_ENV === "demo";

enum LocalStorageKeys {
  RailsSandbox = "sb-rails",
}
/**
 * Builds sandbox baseURL from baseURL and realmId
 * by inserting realmId into the beginning of any API that is supported by sandboxes
 * Format: [protocol]//[realmid].api.[region].[env].mosaicapp.com
 * i.e: https://{realmId}.api.us-east-1.demo.mosaicapp.com - https://mosaic_proddump.api.us-east-1.demo.mosaicapp.com
 */
const _buildUrl = ({
  baseURL,
  realmId,
}: {
  baseURL: string;
  realmId: string;
}) => {
  const { protocol, host, pathname } = new URL(baseURL);

  // https://mosaicapp.slack.com/archives/C03G4RL6E3A/p1659470362967569?thread_ts=1659468577.776349&cid=C03G4RL6E3A
  // should replace [realmid] in the host with passed realmId

  return `${protocol}//${realmId}.${host}${pathname}`;
};

/** A class for managing api related things (i.e: when a sandbox is selected) */
class ApiClientManager {
  private railsSandbox: Sandbox;
  private readonly defaultRailsSandbox: Sandbox = {
    realm_id: DEFAULT_RAILS_REALM_ID, // Technically its not a sandbox, but an option to revert back to regular demo endpoint
    hosts: {},
    created_at: "",
    archived_at: "",
    team_name: "",
    is_ready: true,
    tags: [],
    status: SandboxStatusTypes.Initialized,
    created_by: "",
    archived_by: "",
    realm_type: RealmTypes.sandbox,
  };

  constructor() {
    const railsSandbox = window.sessionStorage.getItem(
      LocalStorageKeys.RailsSandbox
    );
    this.railsSandbox = railsSandbox
      ? JSON.parse(railsSandbox)
      : this.getDefaultRailsSandbox();
  }

  reset() {
    this.removeRailsSandbox();
  }
  /**
   * baseUrl is used for building the url when a non-default sandbox is selected
   * defaultUrl is the default url that will be used if sandbox is not selected
   */
  buildUrl(
    realmId: string,
    isUsingNonDefaultSandbox: boolean,
    baseURL: string,
    defaultUrl: string
  ) {
    const url = isUsingNonDefaultSandbox
      ? _buildUrl({
          realmId,
          baseURL,
        })
      : defaultUrl;

    return url;
  }

  /* ------------------------------ Rails Sandbox ----------------------------- */
  /**
   * Using sandbox means 1) Sandbox is enabled AND 2) Using a non default sandbox
   */
  getIsUsingNonDefaultRailsSandbox() {
    const railsSandbox = this.getRailsSandbox();
    const defaultRailsSandbox = this.getDefaultRailsSandbox();
    const notDefaultSandbox =
      railsSandbox.realm_id !== defaultRailsSandbox.realm_id;

    return this.getIsSandboxEnabled() && notDefaultSandbox;
  }

  getDefaultRailsSandbox() {
    return this.defaultRailsSandbox;
  }

  getRailsSandbox() {
    return this.railsSandbox;
  }

  getRailsSandboxRealmId() {
    return this.getRailsSandbox().realm_id;
  }

  // If we want to persist sandbox even closed browser, use localStorage
  setRailsSandbox(sandbox: Sandbox) {
    window.sessionStorage.setItem(
      LocalStorageKeys.RailsSandbox,
      JSON.stringify(pick(sandbox, ["realm_type", "realm_id"]))
    );
  }

  removeRailsSandbox() {
    window.sessionStorage.removeItem(LocalStorageKeys.RailsSandbox);
  }

  getDefaultRailsServerUrl() {
    return process.env.REACT_APP_RAILS_API_URL as string;
  }

  getRailsServerUrl({
    realmId,
  }: {
    //Should use tenantName for Url once the new api is working
    realmId?: string;
  } = {}) {
    const { realm_id } = this.getRailsSandbox();
    const realmIdToUse = realmId || realm_id;
    const defaultRailsSandbox = this.getDefaultRailsSandbox();
    const IsNonDefaultSandbox = realmIdToUse !== defaultRailsSandbox.realm_id;

    return this.buildUrl(
      realmIdToUse,
      IsNonDefaultSandbox,
      process.env.REACT_APP_SANDBOX_RAILS_API_URL as string,
      this.getDefaultRailsServerUrl()
    );
  }
  /* -------------------------------------------------------------------------- */

  getGalaxyServerUrl() {
    return process.env.REACT_APP_GALAXY_SERVER_API_URL;
  }

  getIntegrationServerUrl() {
    return process.env.REACT_APP_INTEGRATION_SERVER_API_URL;
  }

  getWebappUrl() {
    return process.env.REACT_APP_WEB_APP_URL;
  }

  /**
   *
   * Refer https://mosaic-ai.atlassian.net/l/cp/8GM6vk3q
   */
  getIsRegularSandboxRealmEnabled() {
    return isDemo || isParty;
  }

  /**
   *
   * Refer https://mosaic-ai.atlassian.net/l/cp/8GM6vk3q
   */
  getIsSubscriptionSandboxRealmEnabled() {
    return isProd || isParty;
  }

  /**
   *
   * The only flag for checking whether sandbox is enabled on environmen
   * Useful for checking whether to fetch sandboxes or not
   */
  getIsSandboxEnabled() {
    return (
      this.getIsRegularSandboxRealmEnabled() ||
      this.getIsSubscriptionSandboxRealmEnabled()
      // NOTE: Somehow on prod, fetching sandbox from glx server give 401 (for now)
      // It is reported, once it is fixed, remove this comment
    );
  }

  getIsSubscriptionRealmSelected() {
    const currentRailsSandbox = this.getRailsSandbox();

    return currentRailsSandbox?.realm_type === RealmTypes.subscription;
  }
}

export default new ApiClientManager();
