import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  Paper,
  Button,
  Tooltip,
  TextField,
  FormControlLabel,
} from "@material-ui/core";
import styled from "styled-components";
import { ArrowDropDown } from "@material-ui/icons";
import { SimpleMenuItem } from "types";
import SearchBar from "components/SearchBar";
import { ItemFilter } from "FiltersModule/utils";
import { colors } from "@constants";

type SimpleMenuProps = {
  anchorEl: null | HTMLElement;
  items: SimpleMenuItem[];
  handleClose: () => void;
  handleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  renderAnchorEl?: () => JSX.Element;
  elevation?: number;
  menuMaxHeight?: number;
  showSearchBar?: boolean;
  itemFilter?: ItemFilter;
  className?: string;
  idKey?: string;
};

const StyledPaper = styled(Paper)`
  height: 100%;

  &.transparent-background {
    background-color: transparent;
  }
`;

const noop = () => {};

const SimpleMenu = ({
  anchorEl,
  items,
  handleClose,
  handleClick,
  renderAnchorEl,
  disabled,
  elevation,
  menuMaxHeight,
  showSearchBar,
  itemFilter,
  className,
  idKey,
}: SimpleMenuProps) => {
  const [inputValue, setInputValue] = useState("");

  const itemsToUse =
    showSearchBar && itemFilter && inputValue
      ? items.filter((item) =>
          itemFilter({ item, searchWords: [inputValue], filterKeysArray: [] })
        )
      : items;

  return (
    <StyledPaper
      elevation={elevation ?? 1}
      className={`menu-container ${className}`}
    >
      <div
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}
        className="menu-button-container"
        onClick={disabled ? noop : handleClick}
      >
        {renderAnchorEl ? renderAnchorEl() : <ArrowDropDown />}
      </div>
      <Menu
        MenuListProps={{
          style: {
            border: "1px solid rgba(0,0,0,.1)",
            borderRadius: "5px",
          },
        }}
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            transform: "translateX(10px) translateY(30px)",
            maxHeight: menuMaxHeight,
          },
        }}
      >
        <div>
          {showSearchBar ? (
            <SearchBar inputValue={inputValue} setInputValue={setInputValue} />
          ) : (
            <></>
          )}
          {itemsToUse.map((item, index) => {
            const key = idKey
              ? item[idKey as keyof typeof item]
              : item.value || String(item.label);
            const text = item.getLabel ? item.getLabel() : item.label;

            return (
              <Tooltip
                title={item.tooltip || ""}
                key={`${key}-${index}-tooltip`}
              >
                <StyledMenuItemStyler className={item.className}>
                  <MenuItem
                    disabled={item.disableButton}
                    className="menu-item"
                    style={{
                      borderBottom: item.hasUnderline
                        ? "1px solid rgba(0,0,0,.1)"
                        : "unset",
                    }}
                    key={`${key}-${index}`}
                    onClick={() => {
                      handleClose();
                      item.onClick({ item });
                    }}
                  >
                    {text}
                  </MenuItem>
                </StyledMenuItemStyler>
              </Tooltip>
            );
          })}
        </div>
      </Menu>
    </StyledPaper>
  );
};

const StyledMenuItemStyler = styled.div`
  &.group-header {
    .menu-item {
      justify-content: center;
      background-color: ${colors.lightGrey};
    }
  }

  &.red-text {
    color: ${colors.red};
  }
`;

export default SimpleMenu;
