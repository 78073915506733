import keyBy from "lodash/keyBy";

export const booleanItems = [
  { label: "True", value: "true" },
  { label: "False", value: "false" },
];

export const booleanItemsArray = booleanItems.map((i) => i.value);

export const booleanHash = keyBy(booleanItems, "value");

// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

// Token
export const ENTER_TOKEN = "ENTER_TOKEN";
export const SAVE_TOKEN = "SAVE_TOKEN";

export * from "./paths";
export * from "./report";
export * from "./url";
export * from "./integration";
export * from "./auth";
export * from "./errors";
export * from "./date";
export * from "./colors";
export * from "./loading";
export * from "./filters";
export * from "./roles";
export * from "./members";
export * from "./dataTypes";
export * from "./isoStates";
export * from "./tags";
export * from "./sandbox";
export * from "./sorting";
