import ApiClientManager, { isParty, isProd, isDemo } from "core/apiClient";

const isSandboxEnabled = ApiClientManager.getIsSandboxEnabled();
const isUsingNonDefaultRailsSandbox = ApiClientManager.getIsUsingNonDefaultRailsSandbox();
const isSubscriptionRealmSelected = ApiClientManager.getIsSubscriptionRealmSelected();

/**
 * Refer to the doc below for details. Make sure always match
 * https://mosaic-ai.atlassian.net/l/cp/8GM6vk3q
 */

export const getCanCreateNewTeam = () => {
  if (isProd || isParty) {
    return (
      !isUsingNonDefaultRailsSandbox ||
      (isUsingNonDefaultRailsSandbox && isSubscriptionRealmSelected)
    );
  }

  if (isDemo) return !isUsingNonDefaultRailsSandbox;

  return true;
};

/**
 * Remember that can only create new sandbox on Sandbox page
 */
export const getCanCreateNewSandbox = () => {
  if (isProd) {
    // Not showing sandbox tab on prod anyways
    return !isUsingNonDefaultRailsSandbox;
  }

  if (isDemo) return !isUsingNonDefaultRailsSandbox;

  if (isParty)
    return (
      !isUsingNonDefaultRailsSandbox ||
      (isUsingNonDefaultRailsSandbox && !isSubscriptionRealmSelected)
    );

  return true;
};

export const getIsBulkSetUserPasswordEnabled = () => {
  if (isProd) {
    const isDisabled =
      !isUsingNonDefaultRailsSandbox ||
      (isUsingNonDefaultRailsSandbox && isSubscriptionRealmSelected);

    return !isDisabled;
  }

  if (isDemo) return isUsingNonDefaultRailsSandbox;

  if (isParty)
    return isUsingNonDefaultRailsSandbox && !isSubscriptionRealmSelected;

  // Should only available for sandbox
  return false;
};

export const getIsExportMemberCsvEnabled = () => {
  if (isProd) {
    const isDisabled =
      !isUsingNonDefaultRailsSandbox ||
      (isUsingNonDefaultRailsSandbox && isSubscriptionRealmSelected);

    return !isDisabled;
  }

  if (isDemo) return isUsingNonDefaultRailsSandbox;

  if (isParty)
    return isUsingNonDefaultRailsSandbox && !isSubscriptionRealmSelected;

  // Should only available for sandbox
  return false;
};

export const getIsDevToolTabEnabled = () => {
  if (isProd) {
    return (
      !isSandboxEnabled ||
      !isUsingNonDefaultRailsSandbox ||
      isSubscriptionRealmSelected
    );
  }

  if (isDemo) return !isSandboxEnabled || !isUsingNonDefaultRailsSandbox;

  if (isParty)
    return (
      !isUsingNonDefaultRailsSandbox ||
      (isUsingNonDefaultRailsSandbox && isSubscriptionRealmSelected)
    );

  // Other env (party) - Always show, for cards that uses rails API (budget aggs recal), the selected realm will be used for Url.
  return true;
};

export const getCanSeeSandboxTab = () => {
  if (isParty) {
    return (
      !isUsingNonDefaultRailsSandbox ||
      (isUsingNonDefaultRailsSandbox && !isSubscriptionRealmSelected)
    );
  }

  return isDemo;
};

export const getShouldEnableIntegrationServerConnection = () => {
  if (isProd) {
    const isConnectionDisabled =
      ApiClientManager.getIsUsingNonDefaultRailsSandbox() &&
      ApiClientManager.getIsSubscriptionRealmSelected();

    return !isConnectionDisabled;
  }

  if (isDemo) return false;

  if (isParty) return !isUsingNonDefaultRailsSandbox;

  // Other env - fetch integration
  return true;
};

export const getShouldFetchAvailableDumpFiles = () => {
  return !isProd;
};
