import {
  createContext,
  useContext,
  FunctionComponent,
  useMemo,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from "react";
import useAppOnMount from "hooks/useAppOnMount";
import { useRoutes } from "src/routes/useRoutes";

type AppContextProps = ReturnType<typeof useAppOnMount> &
  ReturnType<typeof useRoutes> & {
    searchIdType: SearchTeamStrategies;
    setSearchTeamStrategies: Dispatch<SetStateAction<SearchTeamStrategies>>;
  };

const AppContext = createContext({} as AppContextProps);

export enum SearchTeamStrategies {
  ByTeamId, // Default
  ByTargetServiceId,
}

export const AppProvider: FunctionComponent<{ children: JSX.Element }> = ({
  children,
}) => {
  const inputRef = useRef(document.createElement("input"));
  const [searchIdType, setSearchTeamStrategies] = useState(
    SearchTeamStrategies.ByTeamId
  );
  const appProps = useAppOnMount();
  const routesProps = useRoutes();

  const value = useMemo(
    () => ({
      ...appProps,
      ...routesProps,
      searchIdType,
      setSearchTeamStrategies,
    }),
    [appProps, routesProps, searchIdType]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
