import keyBy from "lodash/keyBy";
import { getRandomColor } from "utils";

/* ----------------------- Integration data type keys ----------------------- */

export const ACTIVITY = "activity";
export const CLIENT = "client";
export const EMPLOYEE = "employee";
export const PHASE = "phase";
export const PROJECT = "project";
export const TIME_ENTRY = "time_entry";
export const WORK_PLAN = "work_plan";
export const BUDGET_ESTIMATE = "budget_estimate";
export const TASK = "task";
export const EVENTS = "events";
export const SCOPE = "scope";
export const CALENDAR = "calendar";
export const CALENDAR_EVENT = "calendar_event";
export const COST_RATE = "cost_rate";
export const BILL_RATE = "bill_rate";
export const RATE = "rate";
export const MEMBER_PROJECT_RATE = "member_project_rate";
export const ROLE = "role";
export const INVOICE = "invoice";
export const DEPARTMENT = "department";
export const MEMBER_PROJECT_ROLE = "member_project_role";
export const MEMBER_ROLE = "member_role";
export const PTO = "pto";
export const PORTFOLIO = "portfolio";
export const OFFICE = "office";
export const HOLIDAY = "holiday";
export const ENTITY_RATE = "entity_rate";
export const RATE_GROUP = "rate_group";

export const dataTypes = [
  {
    label: "Activity",
    value: ACTIVITY,
    lastUpdateKey: "Item",
    updatedAtKey: "item_updated_at",
    color: getRandomColor(),
  },
  {
    label: "Client",
    value: CLIENT,
    lastUpdateKey: "Customer",
    updatedAtKey: "vendor_updated_at",
    color: getRandomColor(),
  },
  {
    label: "Employee",
    value: EMPLOYEE,
    lastUpdateKey: "Employee",
    updatedAtKey: "employee_updated_at",
    color: getRandomColor(),
  },
  {
    label: "Phase",
    value: PHASE,
    lastUpdateKey: "Customer",
    updatedAtKey: "customer_updated_at",
    color: getRandomColor(),
  },
  {
    label: "Project",
    value: PROJECT,
    lastUpdateKey: "Customer",
    updatedAtKey: "customer_updated_at",
    color: getRandomColor(),
  },
  {
    label: "Time Entry",
    value: TIME_ENTRY,
    lastUpdateKey: "TimeEntry",
    updatedAt: "time_entry_updated_at",
    color: getRandomColor(),
  },
  {
    label: "Work Plan",
    value: WORK_PLAN,
    lastUpdateKey: "WorkPlan",
    color: getRandomColor(),
  },
  {
    label: "Budget Estimate",
    value: BUDGET_ESTIMATE,
    lastUpdateKey: "budget_estimate",
    color: getRandomColor(),
  },
  {
    label: "Task",
    value: TASK,
    lastUpdateKey: "task",
    color: getRandomColor(),
  },
  {
    label: "Events",
    value: EVENTS,
    lastUpdateKey: "events",
    color: getRandomColor(),
  },
  {
    label: "Scope",
    value: SCOPE,
    lastUpdateKey: "scope",
    color: getRandomColor(),
  },
  {
    label: "Calendar",
    value: CALENDAR,
    lastUpdateKey: "calendar",
    color: getRandomColor(),
  },
  {
    label: "Calendar Events",
    value: CALENDAR_EVENT,
    lastUpdateKey: "calendar_event",
    color: getRandomColor(),
  },
  {
    label: "Rate",
    value: RATE,
    lastUpdateKey: "rate",
    color: getRandomColor(),
  },
  {
    label: "Bill Rate",
    value: BILL_RATE,
    lastUpdateKey: "bill_rate",
    color: getRandomColor(),
  },
  {
    label: "Member Project Rate",
    value: MEMBER_PROJECT_RATE,
    lastUpdateKey: "member_project_rate",
    color: getRandomColor(),
  },
  {
    label: "Cost Rate",
    value: COST_RATE,
    lastUpdateKey: "cost_rate",
    color: getRandomColor(),
  },
  {
    label: "Role",
    value: ROLE,
    lastUpdateKey: "role",
    color: getRandomColor(),
  },
  {
    label: "Invoice",
    value: INVOICE,
    lastUpdateKey: "invoice",
    color: getRandomColor(),
  },
  {
    label: "Department",
    value: DEPARTMENT,
    lastUpdateKey: "department",
    color: getRandomColor(),
  },
  {
    label: "Member Project Role",
    value: MEMBER_PROJECT_ROLE,
    lastUpdateKey: "member_project_role",
    color: getRandomColor(),
  },
  {
    label: "Member Role",
    value: MEMBER_ROLE,
    lastUpdateKey: "member_role",
    color: getRandomColor(),
  },
  {
    label: "PTO",
    value: PTO,
    lastUpdateKey: "pto",
    color: getRandomColor(),
  },
  {
    label: "Portfolio",
    value: PORTFOLIO,
    lastUpdateKey: "portfolio",
    color: getRandomColor(),
  },
  {
    label: "Office",
    value: OFFICE,
    lastUpdateKey: "office",
    color: getRandomColor(),
  },
  {
    label: "Holiday",
    value: HOLIDAY,
    lastUpdateKey: "holiday",
    color: getRandomColor(),
  },
  {
    label: "Entity Rate",
    value: ENTITY_RATE,
    lastUpdateKey: "entity_rate",
    color: getRandomColor(),
  },
  {
    label: "Rate Group",
    value: RATE_GROUP,
    lastUpdateKey: "rate_group",
    color: getRandomColor(),
  },
];

export const DATA_TYPES = dataTypes.map((dataType) => dataType.value);

export type DataType = typeof DATA_TYPES[number];

/** This is integration data type, not Backend data type 
 * Do not export, please use getDataType
 */
const dataTypesHash = keyBy(dataTypes, "value");

export const getDataType = (dataTypeVal: DataType) =>
  dataTypesHash[dataTypeVal] || {};

/* ------------------------- Backend Data type keys ------------------------- */
export const BACKEND_DATA_TYPES: Array<string> = ["mosaic-time_entry"];

export const backendDataTypesItems = [
  {
    label: "Time Entry",
    value: "mosaic-time_entry",
  },
];

export const backendDataTypesItemsArray = backendDataTypesItems.map(
  (i) => i.value
);

export const backendDataTypesHash = keyBy(backendDataTypesItems, "value");
