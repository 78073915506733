import { useLocation } from "react-router-dom";
import { getSandboxesTableUrl } from "utils/url";

const useAppLocation = () => {
  const location = useLocation();

  const isOnSandboxPage = location.pathname === getSandboxesTableUrl();

  return {
    isOnSandboxPage,
  };
};

export default useAppLocation;
