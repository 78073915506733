import { keyBy } from "lodash";
import { ValueOf } from "type-fest";
import { intervalsHash } from "../constants/date";

export const MAPPING_STATUS = {
  ACTIVE: "active",
  DO_NOT_LINK: "do_not_link",
  CREATE_NEW_ON_MOSAIC: "CREATE_NEW_ON_MOSAIC",
  CREATE_NEW_ON_TARGET: "CREATE_NEW_ON_TARGET",
  UPDATE_ON_MOSAIC: "UPDATE_ON_MOSAIC",
  UPDATE_ON_TARGET: "UPDATE_ON_TARGET",
  BLOCK_ON_TARGET: "BLOCK_ON_TARGET",
  BLOCK_ON_MOSAIC: "BLOCK_ON_MOSAIC",
  NULL: "null",
};

export const IMPORT_STATUS = {
  Incomplete: "Incomplete",
  Completed: "Completed",
  Processing: "Processing",
  Deleted: "Deleted",
} as const;

export type ImportStatus = ValueOf<typeof IMPORT_STATUS>;

export type MappingStatus = ValueOf<typeof MAPPING_STATUS>;

export const PENDING_MAPPING_STATUS = {
  [MAPPING_STATUS.ACTIVE]: false,
  [MAPPING_STATUS.DO_NOT_LINK]: false,
  [MAPPING_STATUS.BLOCK_ON_TARGET]: false,
  [MAPPING_STATUS.BLOCK_ON_MOSAIC]: false,
  [MAPPING_STATUS.NULL]: false,

  [MAPPING_STATUS.CREATE_NEW_ON_MOSAIC]: true,
  [MAPPING_STATUS.CREATE_NEW_ON_TARGET]: true,
  [MAPPING_STATUS.UPDATE_ON_MOSAIC]: true,
  [MAPPING_STATUS.UPDATE_ON_TARGET]: true,
};

/** Mosaic */

export const PENDING_CREATE_ON_MOSAIC = [
  {
    label: "Create New On Mosaic",
    value: MAPPING_STATUS.CREATE_NEW_ON_MOSAIC,
  },
];

export const PENDING_UPDATE_ON_MOSAIC = [
  {
    label: "Update On Mosaic",
    value: MAPPING_STATUS.UPDATE_ON_MOSAIC,
  },
];

export const PENDING_ON_MOSAIC = [
  ...PENDING_CREATE_ON_MOSAIC,
  ...PENDING_UPDATE_ON_MOSAIC,
];

export const pendingOnMosaicHash = keyBy(PENDING_ON_MOSAIC, "value");
export const pendingCreateOnMosaicHash = keyBy(
  PENDING_CREATE_ON_MOSAIC,
  "value"
);
export const pendingUpdateOnMosaicHash = keyBy(
  PENDING_UPDATE_ON_MOSAIC,
  "value"
);

export const pendingCreateOnMosaicStrings = Object.keys(
  pendingCreateOnMosaicHash
).map((key) => key);
export const pendingUpdateOnMosaicStrings = Object.keys(
  pendingUpdateOnMosaicHash
).map((key) => key);

/** Target */
export const PENDING_CREATE_ON_TARGET = [
  {
    label: "Create New On Target",
    value: MAPPING_STATUS.CREATE_NEW_ON_TARGET,
  },
];
export const PENDING_UPDATE_ON_TARGET = [
  {
    label: "Update On Target",
    value: MAPPING_STATUS.UPDATE_ON_TARGET,
  },
];

export const PENDING_ON_TARGET = [
  ...PENDING_CREATE_ON_TARGET,
  ...PENDING_UPDATE_ON_TARGET,
];

export const pendingOnTargetHash = keyBy(PENDING_ON_TARGET, "value");
export const pendingCreateOnTargetHash = keyBy(
  PENDING_CREATE_ON_TARGET,
  "value"
);
export const pendingUpdateOnTargetHash = keyBy(
  PENDING_UPDATE_ON_TARGET,
  "value"
);

export const pendingOnTargetStrings = Object.keys(pendingOnTargetHash).map(
  (key) => key
);
export const pendingCreateOnTargetStrings = Object.keys(
  pendingCreateOnTargetHash
).map((key) => key);
export const pendingUpdateOnTargetStrings = Object.keys(
  pendingUpdateOnTargetHash
).map((key) => key);

/** Mosaic & Target */
export const ALL_PENDING_MAPPING_STATUS = [
  ...PENDING_ON_MOSAIC,
  ...PENDING_ON_TARGET,
];

export const allPendingMappingStatusHash = keyBy(
  ALL_PENDING_MAPPING_STATUS,
  "value"
);

export const allPendingMappingStatusStrings = Object.keys(
  allPendingMappingStatusHash
).map((key) => key);

/** For Integration API call */
export const INTEGRATION_SERVICE_NAMES = {
  QUICKBOOKS_ONLINE: "quickbooks-online",
  QUICKBOOKS_DESKTOP: "quickbooks-desktop",
  DELTEK_AJERA_CLOUD: "deltek-ajera-cloud",
  DELTEK_AJERA_HOSTED: "deltek-ajera-hosted",
  DELTEK_VISION_CLOUD: "deltek-vision-cloud",
  DELTEK_VISION_HOSTED: "deltek-vision-hosted",
  DELTEK_VANTAGEPOINT_CLOUD: "deltek-vantagepoint-cloud",
  DELTEK_VANTAGEPOINT_HOSTED: "deltek-vantagepoint-hosted",
  CSV_AGENT: "csv-agent",
  JIRA_CLOUD: "jira-cloud",
  GITHUB: "github",
  VIEWPOINT_VISTA_HOSTED: "viewpoint-vista-hosted",
  ASANA: "asana",
  GOOGLE_CALENDAR: "google-calendar",
  SALESFORCE: "salesforce",
  OFFICE365: "office365",
  BST_HOSTED: "bst-hosted",
  MICROSOFT_DYNAMICS_365_PROJECT_OPERATIONS: "ms-project-operations",
  MICROSOFT_DYNAMICS_365_BUSINESS_CENTRAL: "ms-business-central",
  MICROSOFT_365_PROJECT_ONLINE: "ms-project-cloud",
  UNANET_AE: "unanet-ae",
};

export type TargetService = ValueOf<typeof INTEGRATION_SERVICE_NAMES>;

export const AVAILABLE_INTEGRATION_SERVICES = new Set<string>([
  INTEGRATION_SERVICE_NAMES.QUICKBOOKS_ONLINE,
  INTEGRATION_SERVICE_NAMES.QUICKBOOKS_DESKTOP,
  INTEGRATION_SERVICE_NAMES.DELTEK_AJERA_CLOUD,
  INTEGRATION_SERVICE_NAMES.DELTEK_AJERA_HOSTED,
  INTEGRATION_SERVICE_NAMES.DELTEK_VISION_CLOUD,
  INTEGRATION_SERVICE_NAMES.DELTEK_VISION_HOSTED,
  INTEGRATION_SERVICE_NAMES.DELTEK_VANTAGEPOINT_CLOUD,
  INTEGRATION_SERVICE_NAMES.DELTEK_VANTAGEPOINT_HOSTED,
  INTEGRATION_SERVICE_NAMES.CSV_AGENT,
  INTEGRATION_SERVICE_NAMES.JIRA_CLOUD,
  INTEGRATION_SERVICE_NAMES.GITHUB,
  INTEGRATION_SERVICE_NAMES.ASANA,
  INTEGRATION_SERVICE_NAMES.GOOGLE_CALENDAR,
  INTEGRATION_SERVICE_NAMES.SALESFORCE,
  INTEGRATION_SERVICE_NAMES.OFFICE365,
  INTEGRATION_SERVICE_NAMES.VIEWPOINT_VISTA_HOSTED,
  INTEGRATION_SERVICE_NAMES.BST_HOSTED,
  // INTEGRATION_SERVICE_NAMES.MICROSOFT_DYNAMICS_365_BUSINESS_CENTRAL,
  INTEGRATION_SERVICE_NAMES.MICROSOFT_365_PROJECT_ONLINE,
  INTEGRATION_SERVICE_NAMES.MICROSOFT_DYNAMICS_365_PROJECT_OPERATIONS,
  INTEGRATION_SERVICE_NAMES.UNANET_AE, 
]);

// These integrations are on premise integrations that are accessed via a database replica, rather than through SDK or API
export const replicationIntegrations = new Set<string>([
  INTEGRATION_SERVICE_NAMES.DELTEK_AJERA_HOSTED,
  INTEGRATION_SERVICE_NAMES.DELTEK_VANTAGEPOINT_HOSTED,
  INTEGRATION_SERVICE_NAMES.DELTEK_VISION_HOSTED,
  INTEGRATION_SERVICE_NAMES.BST_HOSTED,
]);

// value and label are `Item` type keys. I.e: SingleValuePickerV2
export const integrations = [
  {
    label: "Ajera Cloud",
    id: INTEGRATION_SERVICE_NAMES.DELTEK_AJERA_CLOUD,
    value: "deltek-ajera-cloud",
    provisionModalHeaderLabel: "Deltek Ajera Cloud",
    internalSite: "ajera-cloud",
  },
  {
    label: "Ajera Hosted",
    id: INTEGRATION_SERVICE_NAMES.DELTEK_AJERA_HOSTED,
    value: "deltek-ajera-hosted",
    provisionModalHeaderLabel: "Ajera On-Prem",
    internalSite: "ajera-hosted",
  },
  {
    label: "Asana",
    id: INTEGRATION_SERVICE_NAMES.ASANA,
    internalSite: "asana",
  },
  {
    label: "BST Hosted",
    id: INTEGRATION_SERVICE_NAMES.BST_HOSTED,
    value: INTEGRATION_SERVICE_NAMES.BST_HOSTED,
    provisionModalHeaderLabel: "BST On-Prem",
    // internalSite: "vision-hosted", TODO
  },
  {
    label: "CSV Agent",
    id: INTEGRATION_SERVICE_NAMES.CSV_AGENT,
    value: "csv-agent",
    provisionModalHeaderLabel: "CSV Agent",
    internalSite: "csv-import",
  },
  {
    label: "GitHub",
    id: INTEGRATION_SERVICE_NAMES.GITHUB,
    value: "github",
    provisionModalHeaderLabel: "Github",
    internalSite: "github",
  },
  {
    label: "Google Calendar",
    id: INTEGRATION_SERVICE_NAMES.GOOGLE_CALENDAR,
    internalSite: "google-calendar",
  },
  {
    label: "Jira Cloud",
    id: INTEGRATION_SERVICE_NAMES.JIRA_CLOUD,
    value: "jira-cloud",
    provisionModalHeaderLabel: "Jira Cloud",
    internalSite: "jira",
  },
  // {
  //   label: "Business Central",
  //   id: INTEGRATION_SERVICE_NAMES.MICROSOFT_DYNAMICS_365_BUSINESS_CENTRAL,
  //   shortLabel: "Business Central",
  // },
  {
    label: "Project Online",
    id: INTEGRATION_SERVICE_NAMES.MICROSOFT_365_PROJECT_ONLINE,
    shortLabel: "Project Online",
  },
  {
    label: "Project Operations",
    id: INTEGRATION_SERVICE_NAMES.MICROSOFT_DYNAMICS_365_PROJECT_OPERATIONS,
    shortLabel: "Project Operations",
  },
  {
    label: "Office 365",
    id: INTEGRATION_SERVICE_NAMES.OFFICE365,
    internalSite: "office-365",
  },
  {
    label: "QuickBooks Desktop",
    id: INTEGRATION_SERVICE_NAMES.QUICKBOOKS_DESKTOP,
    value: "quickbooks-desktop",
    provisionModalHeaderLabel: "Quickbooks Desktop",
    internalSite: "quickbooks-desktop",
  },
  {
    label: "QuickBooks Online",
    id: INTEGRATION_SERVICE_NAMES.QUICKBOOKS_ONLINE,
    value: "quickbooks-online",
    provisionModalHeaderLabel: "Quickbooks Online",
    internalSite: "quickbooks-online",
  },
  {
    label: "Salesforce",
    id: INTEGRATION_SERVICE_NAMES.SALESFORCE,
    internalSite: "salesforce",
  },
  {
    label: "Unanet A/E",
    id: INTEGRATION_SERVICE_NAMES.UNANET_AE,
    internalSite: "unanet-ae",
  },
  {
    label: "Vantagepoint Cloud",
    id: INTEGRATION_SERVICE_NAMES.DELTEK_VANTAGEPOINT_CLOUD,
    value: "deltek-vantagepoint-cloud",
    shortName: 'vantagepoint-cloud',
    storeProcedureSupported: true,
    provisionModalHeaderLabel: "Deltek VantagePoint Cloud",
    internalSite: "vantagepoint-cloud",
  },
  {
    label: "Vantagepoint Hosted",
    id: INTEGRATION_SERVICE_NAMES.DELTEK_VANTAGEPOINT_HOSTED,
    value: "deltek-vantagepoint-hosted",
    provisionModalHeaderLabel: "Vantagepoint On-Prem",
    internalSite: "vantagepoint-hosted",
  },
  {
    label: "Vision Cloud",
    id: INTEGRATION_SERVICE_NAMES.DELTEK_VISION_CLOUD,
    shortName: 'vision-cloud',
    storeProcedureSupported: true,
    value: "deltek-vision-cloud",
    provisionModalHeaderLabel: "Deltek Vision Cloud",
    internalSite: "vision-cloud",
  },
  {
    label: "Vision Hosted",
    id: INTEGRATION_SERVICE_NAMES.DELTEK_VISION_HOSTED,
    value: "deltek-vision-hosted",
    provisionModalHeaderLabel: "Vision On-Prem",
    internalSite: "vision-hosted",
  },
  {
    label: "Vista Hosted",
    id: INTEGRATION_SERVICE_NAMES.VIEWPOINT_VISTA_HOSTED,
    value: INTEGRATION_SERVICE_NAMES.VIEWPOINT_VISTA_HOSTED,
    provisionModalHeaderLabel: "Vista Hosted",
    // internalSite: "vision-hosted", TODO
  },
];

export const integrationsHash = keyBy(integrations, "id");

export const INTEGRATION_SERVICE_METRICS_API_DEFAULT_VALUES = {
  OFFSET: 0,
  LIMIT: 1000,
};

// View By
export const GENERAL = "general";
export const TEAM = "team";

// Error Log type, later we will differentiate between IS to BE errors vs IS to Agent errors
export const NETWORK = "network-errors";
export const AGENT = "agent-errors";

// Request Log type
export const API_REQUEST_LOG = "api-request-log";
export const NETWORK_LOG = "network-log";

// PROBABLY have to rename network and agent and put inside a hash maybe?
export const errorTypes = [
  {
    value: "",
    label: "None",
  },
  {
    value: NETWORK,
    label: "Integration Server",
  },
  {
    value: AGENT,
    label: "Agent",
  },
];

export const errorTypesHash = keyBy(errorTypes, "value");

export const defaultErrorType = errorTypesHash[""];

export const defaultCurrentService = "quickbooks-online";

export const defaultSelectedInterval = intervalsHash["year"];

export const developerIntegrationLinks = [
  {
    id: "https://github.com/LifeCoded/mosaic-galaxy",
    label: "Github - Galaxy",
    openInNewTab: true,
  },
  {
    id:
      "https://mosaic-ai.atlassian.net/wiki/spaces/INTG/pages/24215583/Mosaic+Integrations",
    label: "Confluence - Mosaic Integrations",
    openInNewTab: true,
  },
  {
    id:
      "https://mosaic-ai.atlassian.net/wiki/spaces/GLX/pages/40009913/Galaxy+APIs",
    label: "Confluence - Galaxy APIs",
    openInNewTab: true,
  },
];

export const generalIntegrationLinks = [
  {
    id: "https://readme.mosaicapp.com/docs",
    label: "Mosaic Readme",
    openInNewTab: true,
  },
  {
    id:
      "https://sites.google.com/mosaicapp.com/mosaic-internal/integrations/current-integrations",
    label: "Current Integration Page",
    openInNewTab: true,
  },
  {
    id:
      "https://sites.google.com/mosaicapp.com/mosaic-internal/troubleshooting/integrations",
    label: "Integration Troubleshooting Page",
    openInNewTab: true,
  },
  {
    id:
      "https://sites.google.com/mosaicapp.com/mosaic-internal/troubleshooting/galaxy",
    label: "Galaxy Error Table Page",
    openInNewTab: true,
  },
  {
    id:
      "https://sites.google.com/mosaicapp.com/mosaic-internal/internal-processes/sandbox",
    label: "Sandbox Guide",
    openInNewTab: true,
  },
];

export const twoWaySyncIntegrations = new Set([
  INTEGRATION_SERVICE_NAMES.QUICKBOOKS_ONLINE,
  INTEGRATION_SERVICE_NAMES.QUICKBOOKS_DESKTOP,
]);

export const odbcSupportIntegrations = new Set([
  INTEGRATION_SERVICE_NAMES.DELTEK_AJERA_CLOUD,
]);

export const GENERAL_INTEGRATIONS_TABLE_VIEWS = {
  OVERVIEW: "Pending Overview",
  SYNC_RATIO: "sync_ratio",
  ERRORS: "errors",
};

export const GENERAL_INTEGRATION_DEFAULT_TABLE_VIEW =
  GENERAL_INTEGRATIONS_TABLE_VIEWS.OVERVIEW;

export const GENERAL_INTEGRATIONS_TABLE_VIEWS_ARRAY = [
  {
    label: "Pending Overview",
    value: GENERAL_INTEGRATIONS_TABLE_VIEWS.OVERVIEW,
  },
  {
    label: "Sync Ratio",
    value: GENERAL_INTEGRATIONS_TABLE_VIEWS.SYNC_RATIO,
  },
  {
    label: "Errors",
    value: GENERAL_INTEGRATIONS_TABLE_VIEWS.ERRORS,
  },
];

export const standardIntegrationServerDateFormat = "YYYY-MM-DD";
