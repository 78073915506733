import get from "lodash/get";
import { SimpleMenuItem } from "types";

export type ItemFilter = (args: {
  searchWords: string[];
  filterKeysArray: string[];
  item: SimpleMenuItem;
}) => boolean;

export const filterItemWithKeywords: ItemFilter = ({
  searchWords,
  item,
  filterKeysArray,
}) => {
  if (!item) {
    return false;
  }
  if (!searchWords.length) {
    return true;
  }

  if (item.shouldAlwaysShow) return true;

  return searchWords.every((word) =>
    filterKeysArray.some((key) =>
      get(item, key)
        ? String(get(item, key))
            .toLowerCase()
            .includes(word.toLowerCase().trim())
        : false
    )
  );
};
