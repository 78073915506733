import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectAuthToken } from "selectors";
import useAppNavigation from "./useAppNavigation";
import useAppLocation from "hooks/useAppLocation";

export type SignupFieldsProps = {
  email: string;
  company: string;
  skip_credit_card_info: boolean;
  trial_duration: number;
};

export type initialPlusSettingButtonType = {
  showAllAddOptions: boolean;
  showCreateTeamsForm: boolean;
  showCreateSandboxForm: boolean;
  createNewTeamErrorMessage: string;
  createNewTeamSuccessMessage: string;
  showMessage: boolean;
  isLoading: boolean;
  signupFields: SignupFieldsProps;
};

export type plusSettingButtonProps = {
  handleOptionClick: (fromType: string) => void;
  handleInitialOpen: () => void;
  handleClose: (callback: Function) => void;
  showAllAddOptions: boolean;
  showCreateTeamsForm: boolean;
  showCreateSandboxForm: boolean;
  createNewTeamErrorMessage: string;
  createNewTeamSuccessMessage: string;
  showMessage: boolean;
  isLoading: boolean;
  signupFields: SignupFieldsProps;
};

const INITIAL_FIELDS_VALUES = {
  email: "",
  company: "",
  skip_credit_card_info: false,
  trial_duration: 15,
  sales_force_id: "",
  sales_force_link: "",
};

export const INITIAL_PLUS_SETTINGS_BUTTON_STATE: initialPlusSettingButtonType = {
  showAllAddOptions: false,
  showCreateTeamsForm: false,
  showCreateSandboxForm: false,
  createNewTeamErrorMessage: "",
  createNewTeamSuccessMessage: "",
  showMessage: false,
  isLoading: false,
  signupFields: INITIAL_FIELDS_VALUES,
};

export default function usePlusSettingsButton() {
  const history = useHistory();
  const authToken = useSelector(selectAuthToken);
  const { navigateToTeamProfile } = useAppNavigation();
  const { isOnSandboxPage } = useAppLocation();

  const [
    plusSettingButtonState,
    setplusSettingButtonState,
  ] = useState<initialPlusSettingButtonType>(
    INITIAL_PLUS_SETTINGS_BUTTON_STATE
  );

  const handleInitialOpen = () => {
    setplusSettingButtonState({
      ...INITIAL_PLUS_SETTINGS_BUTTON_STATE,
      showAllAddOptions: false,
      showCreateTeamsForm: !isOnSandboxPage,
      showCreateSandboxForm: isOnSandboxPage,
    });
  };

  const handleClose = (callback?: Function) => {
    setplusSettingButtonState({
      ...INITIAL_PLUS_SETTINGS_BUTTON_STATE,
    });
    callback && callback();
  };
  const closeAlert = () =>
    setplusSettingButtonState({
      ...plusSettingButtonState,
      showMessage: false,
    });

  const setLoading = (isLoading: boolean) => {
    setplusSettingButtonState({
      ...plusSettingButtonState,
      isLoading,
    });
  };

  const handleFieldsChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any
  ) => {
    const { name, value, type, checked } = event.target;
    setplusSettingButtonState({
      ...plusSettingButtonState,
      signupFields: {
        ...plusSettingButtonState.signupFields,
        [name]: type === "checkbox" ? checked : value,
      } as any, // Bad workaround
    });
  };

  const handleOptionClick = (fromType: string) => {
    setplusSettingButtonState({
      ...plusSettingButtonState,
      showAllAddOptions: false,
      [fromType]: true,
    });
  };

  return {
    ...plusSettingButtonState,
    setplusSettingButtonState,
    handleClose,
    handleInitialOpen,
    // handleSubmitCreateTeam,
    closeAlert,
    handleFieldsChange,
    handleOptionClick,
  };
}
