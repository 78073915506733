import { useMount } from "react-use";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchActiveIntegrations,
  fetchIntegrationHealth,
  fetchIntegrationAgentDomains,
} from "thunk";
import * as IntegrationTypes from "IntegrationModule/types";
import {
  AVAILABLE_INTEGRATION_SERVICES,
  integrationHealthIsoStateIds,
} from "@constants";
import { formatIntegrationHealthDateParams } from "utils";
import moment from "moment";
import { integrationHealthIsoStateModifiers } from "IntegrationModule/utils";
import Gleap from "gleap";
import { selectAuthAccount } from "selectors";
import { getShouldEnableIntegrationServerConnection } from "utils";
import { useIsSandboxCreationDisabled } from "SandboxModule/hooks/useIsSandboxCreationDisabled";

const useAppOnMount = () => {
  const dispatch = useDispatch();
  const account = useSelector(selectAuthAccount);

  const isSandboxCreationDisabled = useIsSandboxCreationDisabled();

  useMount(() => {
    if (account) {
      Gleap.identify(`user_${account.id}`, {
        name: account.name,
        email: account.email,
      });
      Gleap.attachCustomData({
        id: account.id,
        firstName: account.first_name,
        lastName: account.last_name,
      });
    }

    // fetch active counts and integraiton health

    // Spec: Hide integration side bar and prevent fetch to integration servers
    if (getShouldEnableIntegrationServerConnection()) {
      dispatch(fetchActiveIntegrations());
      dispatch(
        fetchIntegrationAgentDomains({
          onSuccess: ({
            response,
          }: {
            response: IntegrationTypes.FetchIntegrationAgentDomainsResponse;
          }) => {
            const targetServiceToFetch = response.data.map((targetService) => {
              return targetService.service;
            });
            targetServiceToFetch.forEach((targetService) => {
              dispatch(
                fetchIntegrationHealth({
                  targetService,
                  serviceNames: [targetService],
                  startDate: formatIntegrationHealthDateParams(moment()),
                  endDate: formatIntegrationHealthDateParams(moment()),
                  isoStateId: integrationHealthIsoStateIds.IntegrationCard,
                  modifier:
                    integrationHealthIsoStateModifiers[
                      integrationHealthIsoStateIds.IntegrationCard
                    ],
                })
              );
            });
          },
        })
      );
    }
  });

  return {
    isSandboxCreationDisabled,
  };
};

export default useAppOnMount;
