/* eslint-disable import/first */
import React from "react";
import { getSandboxesTableUrl, getEntityErrorPageUrl } from "utils/url";
import async from "../components/Async";
import { isProd } from "core/apiClient";

import {
  BookOpen,
  Briefcase,
  Calendar as CalendarIcon,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
} from "react-feather";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Guards
import AuthGuard from "../components/AuthGuard";

// Auth components
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Components components
import Accordion from "../pages/components/Accordion";
import Alerts from "../pages/components/Alerts";
import Avatars from "../pages/components/Avatars";
import Badges from "../pages/components/Badges";
import Buttons from "../pages/components/Buttons";
import Cards from "../pages/components/Cards";
import Chips from "../pages/components/Chips";
import Dialogs from "../pages/components/Dialogs";
import Lists from "../pages/components/Lists";
import Menus from "../pages/components/Menus";
import Pagination from "../pages/components/Pagination";
import Progress from "../components/Progress";
import Snackbars from "../pages/components/Snackbars";
import Tooltips from "../pages/components/Tooltips";

// Custom components
const Teams = async(() => import("ReportsModule/TeamsOverviewPage"));
const RetoolTeamsTable = async(
  () => import("src/modules/RetoolModule/components/RetoolTeamsTable")
);
const TeamIntegration = async(
  () => import("IntegrationModule/Team/TeamsIntegrationPage")
);
const GeneralIntegration = async(
  () => import("IntegrationModule/General/GeneralIntegrationPage")
);
const PendingEntity = async(
  () => import("IntegrationModule/PendingEntity/PendingEntityTablePage")
);
const Logs = async(() => import("IntegrationModule/Logs/ApiRequestLogsPage"));
const IntegrationHealth = async(
  () => import("IntegrationModule/Health/IntegrationHealthPage")
);
const Administration = async(() => import("ReportsModule/Administration"));
const SandboxesContainer = async(
  () => import("SandboxModule/SandboxesContainer")
);
const DataManagement = async(
  () => import("ReportsModule/Developer/DataManagement/DataManagementPage")
);
const TeamManagement = async(
  () => import("ReportsModule/Developer/TeamManagement/TeamManagementPage")
);
const EntityError = async(
  () => import("IntegrationModule/Logs/EntityError/EntityErrorContainer")
);

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));
const Analytics = async(() => import("../pages/dashboards/Analytics"));
const SaaS = async(() => import("../pages/dashboards/SaaS"));

// Forms components
import SelectionCtrls from "../pages/forms/SelectionControls";
import Selects from "../pages/forms/Selects";
import TextFields from "../pages/forms/TextFields";
const Pickers = async(() => import("../pages/forms/Pickers"));
const Dropzone = async(() => import("../pages/forms/Dropzone"));
const Editors = async(() => import("../pages/forms/Editors"));
const Formik = async(() => import("../pages/forms/Formik"));

// Icons components
import MaterialIcons from "../pages/icons/MaterialIcons";
const FeatherIcons = async(() => import("../pages/icons/FeatherIcons"));

// Pages components
import Blank from "../pages/pages/Blank";
import InvoiceDetails from "../pages/pages/InvoiceDetails";
import InvoiceList from "../pages/pages/InvoiceList";
import Orders from "../pages/pages/Orders";
import Pricing from "../pages/pages/Pricing";
import Settings from "../pages/pages/Settings";
import Projects from "../pages/pages/Projects";
import Chat from "../pages/pages/Chat";
const Profile = async(() => import("ProfileModule/TeamProfilePage"));
const Tasks = async(() => import("../pages/pages/Tasks"));
const Calendar = async(() => import("../pages/pages/Calendar"));

// Tables components
import SimpleTable from "../pages/tables/SimpleTable";
import AdvancedTable from "../pages/tables/AdvancedTable";

// Chart components
const Chartjs = async(() => import("../pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));

// Documentation
import Welcome from "../pages/docs/Welcome";
import GettingStarted from "../pages/docs/GettingStarted";
import EnvironmentVariables from "../pages/docs/EnvironmentVariables";
import Deployment from "../pages/docs/Deployment";
import Theming from "../pages/docs/Theming";
import StateManagement from "../pages/docs/StateManagement";
import ESLintAndPrettier from "../pages/docs/ESLintAndPrettier";
import Support from "../pages/docs/Support";
import Changelog from "../pages/docs/Changelog";
import APICalls from "../pages/docs/APICalls";
import ApiClientManager from "core/apiClient";

import * as constants from "@constants";
import {
  TEAM_PROFILE_PATH_DEEP_LINK,
  REPORTS_TABLE_TEAMS_PATH,
  REPORTS_TABLE_TEAMS_PATH_DEEP_LINK,
} from "@constants";
import IntegrationSidebar from "components/IntegrationSidebar";
import {
  getReportTeamsPageDeepLinkUrl,
  getTeamManagementPageUrl,
  getDataManagementPageUrl,
  getIsDevToolTabEnabled,
  getCanSeeSandboxTab,
  getShouldEnableIntegrationServerConnection,
} from "utils";

const isDevToolTabEnabled = getIsDevToolTabEnabled();
const devToolSectionTooltip = !isDevToolTabEnabled
  ? "Cannot access this section when in a regular sandbox"
  : "";

export const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  header: "Pages",
  icon: <Sliders />,
  containsHome: true,
  children: [
    {
      path: "/dashboard/default",
      name: "Default",
      guard: AuthGuard,
      component: Default,
    },
    {
      path: "/dashboard/analytics",
      name: "Analytics",
      guard: AuthGuard,
      component: Analytics,
    },
    {
      path: "/dashboard/saas",
      name: "SaaS",
      guard: AuthGuard,
      component: SaaS,
    },
  ],
  component: null,
};

// If you need to add route without sidebar, add it here
export const pagesRoutes = {
  id: "Pages",
  path: "/pages",
  icon: <Layout />,
  children: [
    {
      path: TEAM_PROFILE_PATH_DEEP_LINK,
      name: "Profile",
      component: Profile,
      guard: AuthGuard,
    },
    {
      path: `${constants.REPORTS_PENDING_ENTITY_PATH}/targetServiceId/:targetServiceId/teamId/:teamId`,
      name: "Pending Entity",
      component: PendingEntity,
      guard: AuthGuard,
    },
    {
      path: `${constants.LOGS_PATH}/:mosaicTeamId`,
      component: Logs,
      guard: AuthGuard,
    },
    {
      path: constants.REPORTS_INTEGRATION_DETAILS_PATH_DEEP_LINK,
      name: "Team-specific Integration",
      component: TeamIntegration,
      guard: AuthGuard,
    },
    {
      path: constants.INTEGRATION_HEALTH_PATH_DEEP_LINK,
      name: "Integration Health",
      component: IntegrationHealth,
      guard: AuthGuard,
    },
    {
      path: getEntityErrorPageUrl(),
      component: EntityError,
      guard: AuthGuard,
    },
    {
      path: "/pages/settings",
      name: "Settings",
      guard: AuthGuard,
      component: Settings,
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      guard: AuthGuard,
      component: Pricing,
    },
    {
      path: "/pages/chat",
      name: "Chat",
      guard: AuthGuard,
      component: Chat,
    },
    {
      path: "/pages/blank",
      name: "Blank Page",
      guard: AuthGuard,
      component: Blank,
    },
  ],
  component: null,
};

export const projectsRoutes = {
  id: "Projects",
  path: "/projects",
  icon: <Briefcase />,
  badge: "8",
  component: Projects,
  guard: AuthGuard,
  children: null,
};

export const invoiceRoutes = {
  id: "Invoices",
  path: "/invoices",
  icon: <CreditCard />,
  children: [
    {
      path: "/invoices",
      name: "List",
      guard: AuthGuard,
      component: InvoiceList,
    },
    {
      path: "/invoices/detail",
      name: "Details",
      guard: AuthGuard,
      component: InvoiceDetails,
    },
  ],
  component: null,
};

export const orderRoutes = {
  id: "Orders",
  path: "/orders",
  icon: <ShoppingCart />,
  component: Orders,
  guard: AuthGuard,
  children: null,
};

export const tasksRoutes = {
  id: "Tasks",
  path: "/tasks",
  icon: <CheckSquare />,
  badge: "17",
  component: Tasks,
  guard: AuthGuard,
  children: null,
};

export const calendarRoutes = {
  id: "Calendar",
  path: "/calendar",
  icon: <CalendarIcon />,
  component: Calendar,
  guard: AuthGuard,
  children: null,
};

export const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

export const componentsRoutes = {
  id: "Components",
  path: "/components",
  header: "Elements",
  icon: <Grid />,
  children: [
    {
      path: "/components/accordion",
      name: "Accordion",
      guard: AuthGuard,
      component: Accordion,
    },
    {
      path: "/components/alerts",
      name: "Alerts",
      guard: AuthGuard,
      component: Alerts,
    },
    {
      path: "/components/avatars",
      name: "Avatars",
      guard: AuthGuard,
      component: Avatars,
    },
    {
      path: "/components/badges",
      name: "Badges",
      guard: AuthGuard,
      component: Badges,
    },
    {
      path: "/components/buttons",
      name: "Buttons",
      guard: AuthGuard,
      component: Buttons,
    },
    {
      path: "/components/cards",
      name: "Cards",
      guard: AuthGuard,
      component: Cards,
    },
    {
      path: "/components/chips",
      name: "Chips",
      guard: AuthGuard,
      component: Chips,
    },
    {
      path: "/components/dialogs",
      name: "Dialogs",
      guard: AuthGuard,
      component: Dialogs,
    },
    {
      path: "/components/lists",
      name: "Lists",
      guard: AuthGuard,
      component: Lists,
    },
    {
      path: "/components/menus",
      name: "Menus",
      guard: AuthGuard,
      component: Menus,
    },
    {
      path: "/components/pagination",
      name: "Pagination",
      guard: AuthGuard,
      component: Pagination,
    },
    {
      path: "/components/progress",
      name: "Progress",
      guard: AuthGuard,
      component: Progress,
    },
    {
      path: "/components/snackbars",
      name: "Snackbars",
      guard: AuthGuard,
      component: Snackbars,
    },
    {
      path: "/components/tooltips",
      name: "Tooltips",
      guard: AuthGuard,
      component: Tooltips,
    },
  ],
  component: null,
};

export const formsRoutes = {
  id: "Forms",
  path: "/forms",
  icon: <CheckSquare />,
  children: [
    {
      path: "/forms/pickers",
      name: "Pickers",
      guard: AuthGuard,
      component: Pickers,
    },
    {
      path: "/forms/selection-controls",
      name: "Selection Controls",
      guard: AuthGuard,
      component: SelectionCtrls,
    },
    {
      path: "/forms/selects",
      name: "Selects",
      guard: AuthGuard,
      component: Selects,
    },
    {
      path: "/forms/text-fields",
      name: "Text Fields",
      guard: AuthGuard,
      component: TextFields,
    },
    {
      path: "/forms/dropzone",
      name: "Dropzone",
      guard: AuthGuard,
      component: Dropzone,
    },
    {
      path: "/forms/editors",
      name: "Editors",
      guard: AuthGuard,
      component: Editors,
    },
    {
      path: "/forms/formik",
      name: "Formik",
      guard: AuthGuard,
      component: Formik,
    },
  ],
  component: null,
};

export const tablesRoutes = {
  id: "Tables",
  path: "/tables",
  icon: <List />,
  children: [
    {
      path: "/tables/simple-table",
      name: "Simple Table",
      guard: AuthGuard,
      component: SimpleTable,
    },
    {
      path: "/tables/advanced-table",
      name: "Advanced Table",
      guard: AuthGuard,
      component: AdvancedTable,
    },
  ],
  component: null,
};

export const iconsRoutes = {
  id: "Icons",
  path: "/icons",
  icon: <Heart />,
  children: [
    {
      path: "/icons/material-icons",
      name: "Material Icons",
      guard: AuthGuard,
      component: MaterialIcons,
    },
    {
      path: "/icons/feather-icons",
      name: "Feather Icons",
      guard: AuthGuard,
      component: FeatherIcons,
    },
  ],
  component: null,
};

export const chartRoutes = {
  id: "Charts",
  path: "/charts",
  icon: <PieChart />,
  component: Chartjs,
  guard: AuthGuard,
  children: null,
};

export const mapsRoutes = {
  id: "Maps",
  path: "/maps",
  icon: <Map />,
  children: [
    {
      path: "/maps/google-maps",
      name: "Google Maps",
      guard: AuthGuard,
      component: GoogleMaps,
    },
    {
      path: "/maps/vector-maps",
      name: "Vector Maps",
      guard: AuthGuard,
      component: VectorMaps,
    },
  ],
  component: null,
};

export const documentationRoutes = {
  id: "Documentation",
  path: "/documentation",
  header: "Mosaic Galaxy",
  icon: <BookOpen />,
  children: [
    {
      path: "/documentation/welcome",
      name: "Welcome",
      guard: AuthGuard,
      component: Welcome,
    },
    {
      path: "/documentation/getting-started",
      name: "Getting Started",
      guard: AuthGuard,
      component: GettingStarted,
    },
    {
      path: "/documentation/environment-variables",
      name: "Environment Variables",
      guard: AuthGuard,
      component: EnvironmentVariables,
    },
    {
      path: "/documentation/deployment",
      name: "Deployment",
      guard: AuthGuard,
      component: Deployment,
    },
    {
      path: "/documentation/theming",
      name: "Theming",
      guard: AuthGuard,
      component: Theming,
    },
    {
      path: "/documentation/state-management",
      name: "State Management",
      guard: AuthGuard,
      component: StateManagement,
    },
    {
      path: "/documentation/api-calls",
      name: "API Calls",
      guard: AuthGuard,
      component: APICalls,
    },
    {
      path: "/documentation/eslint-and-prettier",
      name: "ESLint & Prettier",
      guard: AuthGuard,
      component: ESLintAndPrettier,
    },
    {
      path: "/documentation/support",
      name: "Support",
      guard: AuthGuard,
      component: Support,
    },
  ],
  component: null,
};

export const changelogRoutes = {
  id: "Changelog",
  path: "/changelog",
  badge: "v2.0.3",
  icon: <List />,
  component: Changelog,
  guard: AuthGuard,
  children: null,
};

/* ------------------------------ Routes in Use ----------------------------- */

export const teamRoutes = {
  id: "Teams",
  icon: <></>,
  prod: true,
  path: REPORTS_TABLE_TEAMS_PATH_DEEP_LINK,
  component: Teams,
  makeSidebarPath: () => getReportTeamsPageDeepLinkUrl({}),
  makeClassnames: (routeMatch: any) => {
    if (routeMatch && routeMatch.url.includes(REPORTS_TABLE_TEAMS_PATH)) {
      return "has-shared-root-url";
    }
  },
};

const integrationsRoutes = {
  id: "Integrations",
  icon: <></>,
  prod: true,
  children: constants.integrations.map((integration) => ({
    path: constants.REPORTS_OVERALL_INTEGRATION_DETAILS_PATH,
    name: "",
    prod: true,
    component: GeneralIntegration,
    integrationId: integration.id,
    CustomSidebarComponent: IntegrationSidebar,
  })),
};

export const devRoutes = isDevToolTabEnabled
  ? {
      id: "Dev Tools",
      icon: <></>,
      prod: true,
      children: [
        {
          path: getDataManagementPageUrl(),
          name: "Data Managment",
          component: DataManagement,
          prod: true,
          // disabled: !isDevToolTabEnabled,
          // tooltip: devToolSectionTooltip,
        },
        {
          path: getTeamManagementPageUrl(),
          name: "Team Managment",
          // disabled: !isDevToolTabEnabled,
          // tooltip: devToolSectionTooltip,
          component: TeamManagement,
          prod: true,
        },
      ],
    }
  : {};

export const adminRoutes = {
  id: "Administration",
  icon: <></>,
  prod: true,
  path: constants.REPORT_SUPER_ADMIN_SECTION_PATH,
  component: Administration,
};

export const sandboxRoutes = getCanSeeSandboxTab()
  ? [
      {
        id: "Sandbox",
        icon: <></>,
        prod: true,
        path: getSandboxesTableUrl(),
        component: SandboxesContainer,
      },
    ]
  : [];

export const teamV2Routes = {
  id: "Teams 2.0",
  icon: <></>,
  prod: true,
  path: constants.REPORTS_TABLE_TEAMS_V2_PATH,
  component: RetoolTeamsTable,
};
export const PricingToolRoutes = {
  id: "Pricing Calculator",
  icon: <></>,
  prod: true,
  path: constants.PRICING_CALCULATOR_PATH,
  component: RetoolTeamsTable,
};
