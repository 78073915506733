import { ValueOf } from "type-fest";
import { SandboxStatusTypes } from "core/apiClient";
export type SandboxViewType = ValueOf<typeof SANDBOX_TABLE_VIEW>;

export const SANDBOX_TABLE_VIEW = {
  ACTIVE: "active",
  ARCHIVED: "archived",
};

export const DEFAULT_SANDBOX_TABLE_VIEW = SANDBOX_TABLE_VIEW.ACTIVE;

export const SANDBOX_TABLE_VIEW_ARRAY = [
  {
    label: "Active",
    value: SANDBOX_TABLE_VIEW.ACTIVE,
  },
  {
    label: "Archived",
    value: SANDBOX_TABLE_VIEW.ARCHIVED,
  },
];

export const activeSandboxStatus = new Set([
  SandboxStatusTypes.Creating,
  SandboxStatusTypes.Initialized,
  SandboxStatusTypes.NotInitialized
]);

export const nonActiveSandboxStatus = new Set([
  SandboxStatusTypes.Archived,
  SandboxStatusTypes.PartiallyRemoved,
]);

// only show on demo - Mosaic Sandbox team
export const SANDBOX_TEAM_WARNING_MESSAGE = "THIS IS THE MAIN DEMO ACCOUNT DO NOT SHARE CREDENTIALS TO THIS ACCOUNT WITH CUSTOMERS"
// errors to show if sandbox team name or tag has uppercase or special characters on edit/create sandbox
export const INPUT_ERROR_MESSAGE = "Cannot contain capital letters or special characters";
// errors to show if tag is stand by on edit/create sandbox
export const STAND_BY_TAG_ERROR_MESSAGE = "This is a reserved tag, please use another one."
// used for checking if realm is demo and is on Mosaic Sandbox
export const MOSAIC_SANDBOX_TEAM_ID = 2848;