import { AppStateType } from "reducers";
import { createSelector } from "reselect";
import * as thunks from "TeamModule/thunks";

export const selectTeamState = (state: AppStateType) =>
  state.teamReducer;

const selectTeamlReducerRequestStatusesAndErrors = createSelector(
  selectTeamState,
  (state) => state.requestStatusesAndErrors
);

export const selectCreateTeamData = createSelector(
  selectTeamlReducerRequestStatusesAndErrors,
  (requestStatusesAndErrors) => ({
    ...requestStatusesAndErrors[thunks.createTeam.typePrefix],
  })
);

export const selectTeamHash = createSelector(
  selectTeamState,
  (state) => state.teamHash
);
