import {
  SIGN_OUT,
  SIGN_IN,
  RESET_AUTH,
  RESET_PASSWORD,
  CONFIRM_PASSWORD_RESET,
  EXCHANGE_TOKEN,
  FETCH_ME
} from "@constants";
import { InitialActionInterface } from "types";
import { createEntityActions } from "actionCreators";

export const signOut = (): InitialActionInterface => {
  return {
    type: SIGN_OUT,
  };
};

export const resetAuth = () => {
  return {
    type: RESET_AUTH,
  };
};

export const signIn = () => createEntityActions(SIGN_IN);

export const resetPassword = () => createEntityActions(RESET_PASSWORD);

export const confirmPasswordReset = () =>
  createEntityActions(CONFIRM_PASSWORD_RESET);

export const exchangeToken = () => createEntityActions(EXCHANGE_TOKEN);