import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateOpenRoutes } from "actionCreators";
import { selectIntegrationAgentDomainsData } from "selectors";
import IntegrationSidebar from "components/IntegrationSidebar";
import { getShouldEnableIntegrationServerConnection } from "utils";
import {
  integrations,
  REPORTS_OVERALL_INTEGRATION_DETAILS_PATH,
} from "@constants";
import async from "../components/Async";
import { RouteChildType } from "types/routes";
import {
  teamRoutes,
  teamV2Routes,
  devRoutes,
  adminRoutes,
  dashboardsRoutes,
  sandboxRoutes,
  pagesRoutes,
  projectsRoutes,
  orderRoutes,
  invoiceRoutes,
  tasksRoutes,
  calendarRoutes,
  componentsRoutes,
  chartRoutes,
  formsRoutes,
  tablesRoutes,
  iconsRoutes,
  mapsRoutes,
  documentationRoutes,
  changelogRoutes,
  authRoutes,
  PricingToolRoutes,
} from ".";
import debounce from "lodash/debounce";
import { useMount } from "react-use";

const GeneralIntegration = async(
  () => import("IntegrationModule/General/GeneralIntegrationPage")
);

export const useRoutes = () => {
  const {
    isRequesting: isLoadingAgentDomains,
    data: agentDomains,
  } = useSelector(selectIntegrationAgentDomainsData);
  const dispatch = useDispatch();

  const integrationRoutes = useMemo(() => {
    // If agentDomains not loaded, then show everything!!
    const integrationRoutesChildren = integrations.reduce<RouteChildType[]>(
      (acc, integration) => {
        // if integration.id in agentDmaoins
        if (isLoadingAgentDomains || agentDomains[integration.id]) {
          acc.push({
            path: REPORTS_OVERALL_INTEGRATION_DETAILS_PATH,
            name: "",
            prod: true,
            component: GeneralIntegration,
            integrationId: integration.id,
            CustomSidebarComponent: IntegrationSidebar,
          });
        }

        return acc;
      },
      []
    );

    return {
      id: "Integrations",
      icon: <></>,
      prod: true,
      children: integrationRoutesChildren,
    };
  }, [agentDomains, isLoadingAgentDomains]);

  const integrationRoutesToUse = useMemo(() => {
    if (getShouldEnableIntegrationServerConnection()) {
      return [integrationRoutes];
    }
    return [];
  }, [integrationRoutes]);

  const routes = useMemo(
    () => [
      teamRoutes,
      ...integrationRoutesToUse,
      teamV2Routes,
      PricingToolRoutes,
      // reportRoutes,
      devRoutes,
      adminRoutes,
      dashboardsRoutes,
      ...sandboxRoutes,
      /* -------------------------------------------------------------------------- */
      pagesRoutes, //
      projectsRoutes,
      orderRoutes,
      invoiceRoutes,
      tasksRoutes,
      calendarRoutes,
      componentsRoutes,
      chartRoutes,
      formsRoutes,
      tablesRoutes,
      iconsRoutes,
      mapsRoutes,
      documentationRoutes,
      changelogRoutes,
    ],
    [integrationRoutesToUse]
  );

  const sidebarRoutes = useMemo(
    () => [
      teamRoutes,
      teamV2Routes,
      PricingToolRoutes,
      ...integrationRoutesToUse,
      // reportRoutes,
      devRoutes,
      adminRoutes,
      ...sandboxRoutes,
      /* -------------------------------------------------------------------------- */
      dashboardsRoutes,
      projectsRoutes,
      orderRoutes,
      invoiceRoutes,
      tasksRoutes,
      calendarRoutes,
      authRoutes,
      componentsRoutes,
      chartRoutes,
      formsRoutes,
      tablesRoutes,
      iconsRoutes,
      mapsRoutes,
      documentationRoutes,
      changelogRoutes,
    ],
    [integrationRoutesToUse]
  );

  useMount(() => {
    const defaultOpenRoutes = sidebarRoutes.reduce<Record<string, boolean>>(
      (acc, route) => {
        if (route.id) {
          acc[route.id] = true;
        }
        return acc;
      },
      {}
    );
    dispatch(updateOpenRoutes(defaultOpenRoutes));
  });

  return { routes, sidebarRoutes };
};
