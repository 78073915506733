import {
  ListItem,
  Dialog,
  DialogTitle,
  List,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { plusSettingButtonProps } from "../hooks/usePlusSettingsButton";
import useAppLocation from "hooks/useAppLocation";

const PlusSettingsOptionsModal = ({
  showAllAddOptions,
  handleOptionClick,
  handleClose,
}: plusSettingButtonProps) => {
  const { isOnSandboxPage } = useAppLocation();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={showAllAddOptions}
    >
      <DialogTitle id="simple-dialog-title">Options</DialogTitle>
      <List>
        {isOnSandboxPage ? (
          <ListItem
            autoFocus
            button
            onClick={() => handleOptionClick("showCreateSandboxForm")}
          >
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Create a sandbox" />
          </ListItem>
        ) : (
          <ListItem
            autoFocus
            button
            onClick={() => handleOptionClick("showCreateTeamsForm")}
          >
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Create a new team" />
          </ListItem>
        )}
      </List>
    </Dialog>
  );
};

export default PlusSettingsOptionsModal;
