import { Sandbox } from "types";
import ApiClientManager from "core/apiClient";
import { SandboxStatusTypes } from "core/apiClient";
import moment from "moment";

export const checkIsSandboxCreating = (status: string) => {
  return status === SandboxStatusTypes.Creating;
};

export const checkIsSandboxInitialized = (status: string) => {
  return status === SandboxStatusTypes.Initialized;
};

export const checkIsSandboxNotInitialized = (status: string) => {
  return status === SandboxStatusTypes.NotInitialized;
};

export const checkIsSandboxFailed = (status: string) => {
  return status === SandboxStatusTypes.Failed;
};

export const checkIsSandboxArchived = (status: string) => {
  return status === SandboxStatusTypes.Archived;
};

export const checkIsSandboxDestroyable = ({
  status,
  created_at,
}: {
  status: Sandbox["status"];
  created_at: Sandbox["created_at"];
}) => {
  const isCreating = checkIsSandboxCreating(status);
  const isCreatingMoreThanTwoHours =
    isCreating && moment().diff(created_at, "hours") > 2;

  return !isCreating || isCreatingMoreThanTwoHours;
};

export const checkIsCurrentlyUsingThisRailsSandbox = (
  realmId: Sandbox["realm_id"]
) => {
  const currentlyUsedRailsSandboxRealmId = ApiClientManager.getRailsSandboxRealmId();

  return realmId === currentlyUsedRailsSandboxRealmId;
};

// When the status is not initialized
export const checkIsSandboxInitializable = (
  isReady: boolean,
  status: string
) => {
  return checkIsSandboxNotInitialized(status);
};

// Accessible = Not creating and is already initialized
export const checkIsSandboxAccessible = (status: string) => {
  return checkIsSandboxInitialized(status);
};
