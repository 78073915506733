import { createReducer } from "@reduxjs/toolkit";
import { GenericTeamInterface } from "types";
import { getTeamProfileUrl } from "utils/url";
import keyBy from "lodash/keyBy";
import * as thunkActions from "thunk";

export type TeamStateType = {
  teamHash: Record<number, GenericTeamInterface>;
  requestStatusesAndErrors: Record<
    string,
    {
      isRequesting: boolean;
      error: Record<string, any>;
    }
  >;
};

const INITIAL_STATE: TeamStateType = {
  teamHash: {},
  requestStatusesAndErrors: {},
};

export default createReducer(INITIAL_STATE, (builder) => {
  /* ------------------------------- Create Team ------------------------------ */
  builder.addCase(thunkActions.createTeam.pending, (state, { payload }) => {
    state.requestStatusesAndErrors[thunkActions.createTeam.typePrefix] = {
      isRequesting: true,
      error: {},
    };
  });
  builder.addCase(thunkActions.createTeam.fulfilled, (state, { payload }) => {
    state.requestStatusesAndErrors[thunkActions.createTeam.typePrefix] = {
      isRequesting: false,
      error: {},
    };
    if (!payload.team_id || !payload.team_created) {
      alert("Create team failed, please try again");
    } else {
      window.location.href = getTeamProfileUrl({ teamId: +payload.team_id });
    }
  });
  builder.addCase(thunkActions.createTeam.rejected, (state, { payload }) => {
    state.requestStatusesAndErrors[thunkActions.createTeam.typePrefix] = {
      isRequesting: false,
      error: payload as any,
    };
    alert("Create team failed, please try again");
  });
  /* -------------------------------------------------------------------------- */
  builder.addCase(
    thunkActions.fetchTeams.fulfilled,
    (state, { payload }) => {
      state.teamHash = {
        ...state.teamHash,
        ...keyBy(payload.teams, "id"),
      };
    }
  );
  /* -------------------------------------------------------------------------- */
  builder.addCase(
    thunkActions.updateTeam.fulfilled,
    (state, { payload, meta }) => {
      state.requestStatusesAndErrors[thunkActions.updateTeam.typePrefix] = {
        isRequesting: false,
        error: {},
      };
      state.teamHash = {
        ...state.teamHash,
        [payload.id]: {
          ...payload,
        },
      };
    }
  );
  /* -------------------------------------------------------------------------- */
  builder.addCase(
    thunkActions.fetchTeamApiToken.fulfilled,
    (state, { payload, meta: { arg } }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamApiToken.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      const { teamId } = arg;
      state.teamHash = {
        ...state.teamHash,
        [teamId]: {
          ...state.teamHash[teamId],
          ...payload,
        },
      };
    }
  );
});
