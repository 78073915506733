import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import ReactDOM from "react-dom";
import App from "./App";

import { Provider } from "react-redux";
import { store, persistor } from "store";
import { PersistGate } from "redux-persist/integration/react";

class ReduxHelper {
  getDispatch() {
    return store.dispatch;
  }
  getState() {
    return store.getState();
  }
}

/** This is only used when the `dispatch` function from Redux is not accessible
 * Always use `dispatch` provided by useDispatch
 *
 * Reason: Accessing "store" in another file on app mount errors out
 */
const reduxHelper = new ReduxHelper();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

export { reduxHelper };
