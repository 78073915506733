import {
  fetchSandboxes as _fetchSandboxes,
  fetchSuperAdmins,
  updateSandbox,
} from "thunk";
import { useSelector, useDispatch } from "react-redux";
import { ValueOf } from "type-fest";
import {
  selectRailsSandboxIsoState,
  selectRailsSandboxesHash,
  selectSandboxReducerRequestStatusesAndErrors,
} from "SandboxModule/selectors";
import ApiClientManager, { DEFAULT_RAILS_REALM_ID } from "core/apiClient";
import { FetchSandboxParams } from "../types";
import { useCallback } from "react";
import useAppNavigation from "hooks/useAppNavigation";
import { Sandbox } from "types";
import { RealmTypes } from "RealmModule/constants/realm";
import { defaultSandboxIsoState } from "SandboxModule/reducers";
import { selectAuthUser } from "selectors";
import { FetchSuperAdminsSuccessResponse } from "ReportsModule/types";

export enum SandboxIsoStateIds {
  AppbarRealmSelector = "AppbarRealmSelector",
  SandboxTable = "SandboxTable",
  SandboxCreationTimer = "SandboxCreationTimer",
}

export type SandboxIsoStateIdsValues = ValueOf<typeof SandboxIsoStateIds>;

const useSandboxes = () => {
  const dispatch = useDispatch();
  const railsSandboxesByIsoState = useSelector(selectRailsSandboxIsoState);
  const railsSandboxesHash = useSelector(selectRailsSandboxesHash);
  const requestStatuses = useSelector(
    selectSandboxReducerRequestStatusesAndErrors
  );
  const { navigateToTeamsTable } = useAppNavigation();
  const user = useSelector(selectAuthUser);
  const fetchSandboxes = useCallback(
    (
      isoStateId: ValueOf<typeof SandboxIsoStateIds>,
      params?: Partial<FetchSandboxParams>
    ) => {
      if (ApiClientManager.getIsSandboxEnabled()) {
        let realmTypes = params?.realmTypes || [
          RealmTypes.sandbox,
          RealmTypes.subscription,
        ]; // Default to fetching all sandboxes types

        // Spec: If Subscription realm sandbox is not enabled, should not fetch for it
        if (!ApiClientManager.getIsSubscriptionSandboxRealmEnabled()) {
          realmTypes = realmTypes.filter(
            (realmType) => realmType !== RealmTypes.subscription
          );
        }

        // Spec: If Regular realm sandbox is not enabled, should not fetch for it
        if (!ApiClientManager.getIsRegularSandboxRealmEnabled()) {
          realmTypes = realmTypes.filter(
            (realmType) => realmType !== RealmTypes.sandbox
          );
        }

        dispatch(
          _fetchSandboxes({
            includeArchived: true,
            isoStateId,
            ...params,
            realmTypes,
          })
        );
      }
    },
    [dispatch]
  );

  const getSandboxesByIsoState = useCallback(
    (isoStateId: ValueOf<typeof SandboxIsoStateIds>) => {
      const sandboxIsoStateData = {
        ...(railsSandboxesByIsoState[isoStateId] || defaultSandboxIsoState),
        ...requestStatuses[isoStateId],
      };
      return sandboxIsoStateData;
    },
    [railsSandboxesByIsoState, requestStatuses]
  );

  const getSandbox = useCallback(
    (realmId: string): Sandbox | undefined => {
      if (realmId === DEFAULT_RAILS_REALM_ID)
        return ApiClientManager.getDefaultRailsSandbox();

      return railsSandboxesHash[realmId];
    },
    [railsSandboxesHash]
  );

  const getSelectedRailsSandbox = useCallback(() => {
    const selectedRailsSandboxRealmId = ApiClientManager.getRailsSandboxRealmId();
    return getSandbox(selectedRailsSandboxRealmId);
  }, [getSandbox]);

  const getDefaultRailsSandbox = useCallback(() => {
    return getSandbox(ApiClientManager.getDefaultRailsSandbox().realm_id);
  }, [getSandbox]);

  const handleSelectRailsSandbox = useCallback(
    (sandbox: Sandbox) => {
      const params: Parameters<typeof fetchSuperAdmins>[0] = {
        realmId: sandbox.realm_id,
        meta: {
          onSuccess: () => {
            ApiClientManager.setRailsSandbox(sandbox);
            navigateToTeamsTable();
            window.location.reload();
          },
          onFailure: (e: any) => {
            // fetch using unauthorized account will fail
            alert(
              "You are not a super admin, you don't have access to this realm"
            );
          },
        },
      };
      dispatch(fetchSuperAdmins(params));
    },
    [dispatch, navigateToTeamsTable]
  );

  return {
    getSandboxesByIsoState,
    getSandbox,
    getSelectedRailsSandbox,
    getDefaultRailsSandbox,
    handleSelectRailsSandbox,
    fetchSandboxes,
  };
};

export default useSandboxes;
