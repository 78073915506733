import keyBy from "lodash/keyBy";

export const REPORT_TEAMS_FETCH_LIMIT = 100;

export const REPORT_TEAMS_UTILIZATION_LIMIT = 50;
export const REPORT_TEAMS_UTILIZATION_THRESHOLD = 0.5;
export const REPORT_TEAMS_UTILIZATION_COMPLIANT_START_DATE = 90;

export const REPORTS_VIEW_TYPES = {
  TEAMS: "customers",
  DEACTIVATED_TEAMS: "deactivated_customers",
  COUNT_STATISTICS: "counts_statistics",
  UTILIZATION: "utilization",
};

export const DEFAULT_REPORTS_VIEW_TYPES = REPORTS_VIEW_TYPES.COUNT_STATISTICS;

export const REPORTS_VIEW_TYPES_ARRAY = [
  {
    label: "Teams Overview",
    value: REPORTS_VIEW_TYPES.COUNT_STATISTICS,
  },
  {
    label: "Customers",
    value: REPORTS_VIEW_TYPES.TEAMS,
  },
  {
    label: "Deactivated Customers",
    value: REPORTS_VIEW_TYPES.DEACTIVATED_TEAMS,
  },
  {
    label: "Utilization Overview",
    value: REPORTS_VIEW_TYPES.UTILIZATION,
  },
];

export const REPORTS_VIEW_TYPES_HASH = keyBy(REPORTS_VIEW_TYPES_ARRAY, "value");
