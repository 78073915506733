import useSandboxes, {
  SandboxIsoStateIdsValues,
} from "SandboxModule/hooks/useSandboxes";
import { useEffect, useMemo } from "react";
import useBulkActionsSelector from "hooks/useBulkActionsSelector";
import { filterItemWithKeywords } from "FiltersModule/utils";
import Box from "@material-ui/core/Box";
import { checkIsSandboxAccessible } from "utils/sandbox";
import { DEFAULT_RAILS_REALM_ID } from "core/apiClient";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import ApiClientManager from "core/apiClient";
import orderBy from "lodash/orderBy";
import cn from "classnames";
import { RealmTypes } from "RealmModule/constants/realm";
import noop from "lodash/noop";
import { SimpleMenuItem } from "types";
import { getRealmLabel } from "RealmModule/utils/realm";

type HeaderMenuItem = SimpleMenuItem & { isHeader: true };

const makeHeaderMenuItem = (label: string): HeaderMenuItem => ({
  isHeader: true,
  label,
  onClick: noop,
  disableButton: true,
  shouldAlwaysShow: true,
  className: "group-header",
});

const makeSandboxLabel = (realmId: string, teamName: Nullable<string>) => {
  return (
    <Tooltip title="If your account does not exist on the realm, you will be logged out">
      <span>
        {teamName ? `${teamName}` : `${realmId ?? "Loading..."}`}{" "}
        {realmId === DEFAULT_RAILS_REALM_ID ? (
          <Box fontSize={"12px"} color={"red"} display={"inline-block"}>
            | DO NOT SHARE
          </Box>
        ) : (
          ""
        )}
      </span>
    </Tooltip>
  );
};

const useSandboxSelector = ({
  isoStateId,
}: {
  isoStateId: SandboxIsoStateIdsValues;
}) => {
  const {
    fetchSandboxes,
    getSandboxesByIsoState,
    getSandbox,
    getSelectedRailsSandbox,
    getDefaultRailsSandbox,
    handleSelectRailsSandbox,
  } = useSandboxes();
  const selectedRailsSandbox = getSelectedRailsSandbox();
  const defaultRailsSandbox = getDefaultRailsSandbox();

  useEffect(() => {
    fetchSandboxes(isoStateId);
  }, [fetchSandboxes, isoStateId]);

  const {
    isRequesting: isFetchingSandboxes,
    ordersByGroup,
  } = getSandboxesByIsoState(isoStateId);

  const menuItems = useMemo(() => {
    const items: SimpleMenuItem[] = [];

    if (defaultRailsSandbox) {
      const defaultRailsSandboxMenuItem: SimpleMenuItem = {
        label: (
          <span>
            {defaultRailsSandbox.realm_id}{" "}
            <Box fontSize={"12px"} color={"red"} display={"inline-block"}>
              | DO NOT SHARE
            </Box>
          </span>
        ),
        onClick: () => {
          handleSelectRailsSandbox(defaultRailsSandbox);
        },
        disableButton: isFetchingSandboxes,
        value: defaultRailsSandbox.realm_id,
      };

      const defaultSandboxHeaderMenuItem = makeHeaderMenuItem(
        getRealmLabel(RealmTypes.sandbox)
      );
      items.push(defaultSandboxHeaderMenuItem);
      items.push(defaultRailsSandboxMenuItem);
    }

    Object.values(RealmTypes).forEach((realmType) => {
      const order = ordersByGroup[realmType];

      if (order?.length) {
        if (realmType !== RealmTypes.sandbox) {
          const headerMenuItem: HeaderMenuItem = makeHeaderMenuItem(
            getRealmLabel(realmType)
          );
          items.push(headerMenuItem);
        }
        const sandboxesArray =
          order.reduce<SimpleMenuItem[]>((acc, id) => {
            const sb = getSandbox(id);

            if (sb && checkIsSandboxAccessible(sb.status)) {
              acc.push({
                label: makeSandboxLabel(sb.realm_id, sb.team_name),
                onClick: () => {
                  if (sb.realm_id !== selectedRailsSandbox?.realm_id) {
                    handleSelectRailsSandbox(sb);
                  }
                },
                disableButton: isFetchingSandboxes,
                value: `${sb.team_name?.toLocaleLowerCase()} ${sb.realm_id}`,
              });
            }
            return acc;
          }, []) || [];

        items.push(...orderBy(sandboxesArray, ["value"], ["asc"]));
      }
    });
    return items;
  }, [
    defaultRailsSandbox,
    getSandbox,
    handleSelectRailsSandbox,
    isFetchingSandboxes,
    ordersByGroup,
    selectedRailsSandbox?.realm_id,
  ]);

  const { BulkActionsSelector: SandboxSelector } = useBulkActionsSelector({
    selectedItemsIds: [],
    itemFilter: ({ searchWords, item }) =>
      filterItemWithKeywords({
        searchWords,
        item,
        filterKeysArray: ["value"],
      }),
    menuItems,
    anchorElProps: {
      isDisabled: isFetchingSandboxes,
      renderCustomAnchorEl: ({ name }) => (
        <Tooltip title={isFetchingSandboxes ? "Loading sandboxes..." : ""}>
          <StyledButton
            className={cn("sandbox", {
              loading: isFetchingSandboxes,
            })}
            variant="outlined"
            color="primary"
          >
            {selectedRailsSandbox
              ? makeSandboxLabel(
                  selectedRailsSandbox.realm_id,
                  selectedRailsSandbox.team_name
                )
              : "Loading..."}
          </StyledButton>
        </Tooltip>
      ),
    },
    menuMaxHeight: 300,
    showSearchBar: true,
  });

  return ApiClientManager.getIsSandboxEnabled() ? (
    <Box ml={4}>{SandboxSelector}</Box>
  ) : (
    <></>
  );
};

export default useSandboxSelector;

const StyledButton = styled(Button)`
  &.sandbox {
    &.loading {
      cursor: not-allowed !important;
      border: 1px solid lightgrey !important;
      color: lightgrey !important;
    }
  }
`;
