import { combineReducers } from "redux";

import themeReducer from "./theme";
import authReducer from "AuthModule/reducers";
import reportsReducer from "ReportsModule/reducers";
import profileReducer from "ProfileModule/reducers";
import integrationReducer from "IntegrationModule/reducers/team";
import overallIntegrationReducer from "IntegrationModule/reducers/general";
import globalReducer from "GlobalModule/reducers";
import filterReducer from "./filters";
import sandboxReducer from "SandboxModule/reducers";
import csmReducer from "CsmModule/reducers/csm";
import teamReducer from "TeamModule/reducers";
import { redirectToLoginScreen } from "utils"; 
import requestStatusReducer from "RequestStatusModule/reducers";

import { SIGN_OUT } from "@constants";
import ApiClientManager from "core/apiClient";

export const appReducer = combineReducers({
  themeReducer,
  authReducer,
  reportsReducer,
  profileReducer,
  overallIntegrationReducer,
  integrationReducer,
  filterReducer,
  globalReducer,
  sandboxReducer,
  teamReducer,
  csmReducer,
  requestStatusReducer
});

const rootReducer = (
  state: AppStateType | undefined,
  action: any
): AppStateType => {
  if (action.type === SIGN_OUT) {
    ApiClientManager.reset();
    setTimeout(() => {
      // Need to clear out state before redirecting
      redirectToLoginScreen();
    }, 1000);
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;

export type RootReducerType = typeof appReducer;
export type AppStateType = ReturnType<RootReducerType>;
