import { createReducer } from "@reduxjs/toolkit";
import { ValueOf } from "type-fest";
import groupBy from "lodash/groupBy";
import * as thunkActions from "thunk";
import * as actions from "actionCreators";
import * as constants from "@constants";
import { serializeId } from "utils";
import { Sandbox } from "types";
import keyBy from "lodash/keyBy";
import omit from "lodash/omit";
import ApiClientManager from "core/apiClient";
import {
  defaultInitialIsoState,
  DefaultIsoState,
} from "IsoStateModule/constants";
import { RealmTypes, RealmTypesValues } from "RealmModule/constants/realm";
import { SandboxIsoStateIds } from "SandboxModule/hooks/useSandboxes";
import { SandboxStatusTypes } from "core/apiClient";
import { FetchSandboxSuccessResponse } from "SandboxModule/types";

export const defaultSandboxIsoState: DefaultIsoState<string> &
  FetchSandboxSuccessResponse = {
  ...defaultInitialIsoState,
  count: {
    all: 0,
    standby_available: 0,
},
  sandbox_info: [],
};

export type InitialStateType = {
  railsSandboxes: Record<string, typeof defaultSandboxIsoState>;
  railsSandboxesHash: Record<string, Sandbox>;
  dumpFiles: string[];
  requestStatusesAndErrors: {
    [x: string]: {
      // can be serialized
      isRequesting: boolean;
      error: Record<string, any>;
    };
  };
};

const sb1: Sandbox = {
  realm_id: "REGULAR SANDBOX REALM",
  is_ready: false,
  created_at: "",
  tags: [],
  status: SandboxStatusTypes.Initialized,
  archived_at: null,
  team_name: "",
  archived_by: null,
  created_by: "",
  realm_type: RealmTypes.sandbox,
};

const sb2: Sandbox = {
  realm_id: "Subscription realm",
  is_ready: false,
  created_at: "",
  tags: [],
  status: SandboxStatusTypes.Initialized,
  archived_at: null,
  team_name: "",
  archived_by: null,
  created_by: "",
  realm_type: RealmTypes.subscription,
};

const stub = [sb1, sb2];

export const initialState: InitialStateType = {
  requestStatusesAndErrors: {},
  dumpFiles: [],
  railsSandboxes: {},
  railsSandboxesHash: {},

  // For Testing
  // railsSandboxes: {
  //   [SandboxIsoStateIds.AppbarRealmSelector]: {
  //     topLevelOrder: [],
  //     groupTotalCounts: {
  //       [RealmTypes.sandbox]: 1,
  //       [RealmTypes.subscription]: 1,
  //     },
  //     totalCount: 0,
  //     totalFetchedCount: 2,
  //     ordersByGroup: {
  //       [RealmTypes.sandbox]: ["REGULAR SANDBOX REALM"],
  //       [RealmTypes.subscription]: ["Subscription realm"],
  //     },
  //   },
  // },
  // railsSandboxesHash: keyBy(stub, "realm_id"),
};

export default createReducer(initialState, (builder) => {
  /* ---------------------------- Non Async Request --------------------------- */
  //   builder.addCase(actions.setGeneralIntegrationState, (state, action) => {
  //     state = {
  //       ...state,
  //       ...action.payload,
  //     };
  //   });
  builder.addCase(actions.setSelectedRailsSandbox, (state, { payload }) => {
    // state.selectedRailsSandbox = payload.sandbox; - if want the input to change immediately on click, use this
    ApiClientManager.setRailsSandbox(payload.sandbox);
  });
  /* --------------------------------Async Request------------------------------------------ */
  builder.addCase(thunkActions.fetchSandboxes.pending, (state, action) => {
    const { meta } = action;
    const { arg } = meta;
    const { isoStateId } = arg;
    if (isoStateId && !state.railsSandboxes[isoStateId]) {
      state.railsSandboxes[isoStateId] = defaultSandboxIsoState;
      state.requestStatusesAndErrors[isoStateId] = {
        isRequesting: true,
        error: {},
      };
    }
  });
  builder.addCase(
    // Note that fetchSandboxes are fetching RAILS sandbox
    thunkActions.fetchSandboxes.fulfilled,
    (state, { meta, payload }) => {
      const { arg } = meta;
      const { isoStateId } = arg;

      // Can use `stub` for testing
      // const sandboxesStub: Sandbox[] = (stub | payload.sandbox_info).map(
      //   (sb: Sandbox, index: number) => ({
      //     ...sb,
      //     realm_type:
      //       index % 2 === 0 ? RealmTypes.sandbox : RealmTypes.subscription,
      //   })
      // );

      // ^ For testing
      const sandboxes: Sandbox[] = payload.sandbox_info;

      state.railsSandboxesHash = {
        ...state.railsSandboxesHash,
        ...keyBy(sandboxes, "realm_id"),
      };

      /**
       * Iso state
       */
      if (isoStateId && state.railsSandboxes[isoStateId]) {
        const ordersByGroup = sandboxes.reduce(
          (acc: Record<RealmTypesValues | string, string[]>, sb) => {
            if (!acc[sb.realm_type]) {
              acc[sb.realm_type] = [sb.realm_id];
            } else {
              acc[sb.realm_type].push(sb.realm_id);
            }
            return acc;
          },
          {}
        );

        state.railsSandboxes[isoStateId] = {
          ...state.railsSandboxes[isoStateId],
          topLevelOrder: sandboxes.map((sb) => sb.realm_id),
          /**
           * Note: the group key here is realm_type
           */
          ordersByGroup,
          ...payload,
        };
        state.requestStatusesAndErrors[isoStateId] = {
          isRequesting: false,
          error: {},
        };
      }
    }
  );
  builder.addCase(
    thunkActions.fetchSandboxes.rejected,
    (state, { meta, payload, error }) => {
      const { arg } = meta;
      const { isoStateId } = arg;

      if (isoStateId) {
        state.requestStatusesAndErrors[isoStateId] = {
          isRequesting: false,
          error,
        };
      }
    }
  );
  builder.addCase(thunkActions.deleteSandboxes.pending, (state, action) => {
    state.requestStatusesAndErrors[thunkActions.deleteSandboxes.typePrefix] = {
      isRequesting: true,
      error: {},
    };
  });
  builder.addCase(
    thunkActions.deleteSandboxes.fulfilled,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.deleteSandboxes.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      const { arg } = meta;
      state.railsSandboxesHash = omit(state.railsSandboxesHash, arg.realmIds);
    }
  );
  builder.addCase(thunkActions.deleteSandboxes.rejected, (state, action) => {
    state.requestStatusesAndErrors[thunkActions.deleteSandboxes.typePrefix] = {
      isRequesting: false,
      error: action.error,
    };
  });
  /* -------------------------------------------------------------------------- */
  builder.addCase(
    thunkActions.fetchAvailableDumpFiles.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchAvailableDumpFiles.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchAvailableDumpFiles.fulfilled,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchAvailableDumpFiles.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      const { arg } = meta;
      state.dumpFiles = payload.files;
    }
  );
  builder.addCase(
    thunkActions.fetchAvailableDumpFiles.rejected,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchAvailableDumpFiles.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.initializeSandbox.fulfilled,
    (state, { meta, payload }) => {
      const { status, sandbox } = payload;
      if (status < 300 && sandbox) {
        state.railsSandboxesHash[sandbox.realm_id] = sandbox;
      }
    }
  );
  builder.addCase(
    thunkActions.updateSandbox.fulfilled,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[thunkActions.updateSandbox.typePrefix] = {
        isRequesting: false,
        error: {},
      };
      const { updated_sandbox } = payload.data;
      state.railsSandboxesHash[updated_sandbox.realm_id] = updated_sandbox;
    }
  );
});
