import { ValueOf } from "type-fest";

export enum RealmTypes {
  sandbox = "Sandbox", // Matching glx server values
  subscription = "Subscription",
}

export type RealmTypesValues = ValueOf<typeof RealmTypes>;

export const realmTypeConfigs = {
  [RealmTypes.sandbox]: {
    value: RealmTypes.sandbox,
    label: "Sandbox",
  },
  [RealmTypes.subscription]: {
    value: RealmTypes.subscription,
    label: "Subscription",
  },
} as const;
