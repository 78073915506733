import { useEffect, useState } from "react";
import moment, { Moment } from "moment-timezone";
import useSandboxes, {
  SandboxIsoStateIds,
} from "SandboxModule/hooks/useSandboxes";

const checkIsWeekend = (momentObj: Moment) => {
  const day = momentObj.day();

  // If the value given is from 0 to 6, the resulting date will be within the current (Sunday-to-Saturday)
  return day === 0 || day === 6;
};

/**
/** 
 * Please access the return of this hook via useAppContext, as this hook contains a timer
 */
export const useIsSandboxCreationDisabled = () => {
  const { getSandboxesByIsoState } = useSandboxes();
  const { count } = getSandboxesByIsoState(
    SandboxIsoStateIds.SandboxCreationTimer
  );
  const { standby_available } = count;
  const [isSandboxCreationDisabled, setIsSandboxCreationDisabled] = useState(
    true
  );

  /**
   * Spec: Sandbox creation will be disabled if
   * - During the Workdays, in EST
   * - AND if there are NO standby sandboxes
   * https://mosaicapp.slack.com/archives/C040HFV5SEA/p1673548963538569
   */

  useEffect(() => {
    const timer = setInterval(() => {
      const now = moment.tz("America/New_York");
      const hour = now.hour();
      const isWeekend = checkIsWeekend(now); // If weekend, will be able to create still
      const isWorkdays = !isWeekend;
      const hasStandbySandboxes = standby_available > 0;
      const isWithinForbiddenTimeRange = isWorkdays && hour >= 9 && hour < 17;

      const isDisabled = !hasStandbySandboxes && isWithinForbiddenTimeRange; // If need to disable 5:01, then hour <= 17
      setIsSandboxCreationDisabled(isDisabled);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [standby_available]);

  return isSandboxCreationDisabled;
};
