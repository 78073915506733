import {
  postApiRailsServer,
  getApiRailsServer,
  deleteApiRailsServer,
} from "services";
import {
  createTeamParams,
  DeleteBatchTimeEntriesPayload,
  ExportDeletedWorkPlanCsvParams,
} from "TeamModule/types";

export const createTeam = (
  params: createTeamParams,
  config = {},
  token: string
) => postApiRailsServer("/signup", params, config, token);

export const getTeams = (config: {}, token: string = "") =>
  getApiRailsServer("/teams/all", config, token);

export const deleteBatchTimeEntries = (
  _ = {},
  body: DeleteBatchTimeEntriesPayload,
  config: {},
  token: string = ""
) => deleteApiRailsServer("/time_entries/batch_delete", body, config, token);

export const exportDeletedWorkPlanCsv = (
  params: ExportDeletedWorkPlanCsvParams,
  config = {},
  token: string
) => {
  return postApiRailsServer("/data_recovery", params, config, token);
};
