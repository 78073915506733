import React, { useEffect, useRef, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { withTheme } from "styled-components/macro";
import { darken } from "polished";
import {
  Search as SearchIcon,
  AlertCircle,
  X as ClearIcon,
} from "react-feather";
import { debounce } from "lodash";
import { SandboxIsoStateIds } from "SandboxModule/hooks/useSandboxes";
import useAppNavigation from "hooks/useAppNavigation";
import useSandboxSelector from "SandboxModule/hooks/useSandboxSelector";
import * as constants from "@constants";
import { useAppContext, SearchTeamStrategies } from "containers/AppProvider";
import * as Types from "types";
import {
  Grid,
  Hidden,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Tooltip,
  FormControl,
  MenuItem,
  Box,
  Select,
} from "@material-ui/core";

import Switch from "@material-ui/core/Switch";

import { Menu as MenuIcon } from "@material-ui/icons";

import NotificationsDropdown from "./NotificationsDropdown";
import MessagesDropdown from "./MessagesDropdown";
import LanguagesDropdown from "./LanguagesDropdown";
import UserDropdown from "./UserDropdown";
import { toggleDevMode as _toggleDevMode } from "actionCreators";

import { selectIsDevMode, selectReportsTeamsData } from "selectors";

import { fetchReportsTeams, fetchIntegrations } from "thunk";

import { getReportsTeamSearchValue } from "utils";
import { REPORTS_VIEW_TYPES, KEYBOARD_KEYS } from "@constants";
import { useRouteMatch } from "react-router-dom";
import { FetchReportsTeamsParams } from "ReportsModule/types";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 300px;
  }
`;

type AppBarProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const sandboxIsoStateId = SandboxIsoStateIds.AppbarRealmSelector;

const AppBarComponent: React.FC<AppBarProps> = ({ onDrawerToggle }) => {
  const dispatch = useDispatch();
  const toggleDevMode = () => dispatch(_toggleDevMode());
  const isDevMode: boolean = useSelector(selectIsDevMode);
  const {
    params: { memberTableView, reportTableView },
  } = useRouteMatch<{ memberTableView?: string; reportTableView?: string }>();
  const { isRequesting: isFetchingTeams, filter } = useSelector(
    selectReportsTeamsData
  );
  const { navigateToTeamProfile, navigateToTeamsTable } = useAppNavigation();
  const SandboxSelector = useSandboxSelector({ isoStateId: sandboxIsoStateId });

  const inputRef = useRef(document.createElement("input"));
  const { searchIdType, setSearchTeamStrategies } = useAppContext();
  const isOnRetoolPage =
    window.location.pathname === constants.REPORTS_TABLE_TEAMS_V2_PATH;

  const searchTeams = ({
    valueToSearch,
  }: {
    valueToSearch: {
      ids: number[];
      search_text: string;
    };
  }) => {
    const fetchReportsTeamsOnSuccess: FetchReportsTeamsParams["onSuccess"] = ({
      response,
    }) => {
      const { teams } = response.data;
      if (
        teams.length === 1 &&
        reportTableView !== REPORTS_VIEW_TYPES.DEACTIVATED_TEAMS
      ) {
        const navigateToProfile = navigateToTeamProfile(
          teams[0].id,
          memberTableView
        );
        navigateToProfile();
        return;
      }
      navigateToTeamsTable(REPORTS_VIEW_TYPES.TEAMS);
    };
    const shouldShowDeactivateTeams =
      reportTableView === REPORTS_VIEW_TYPES.DEACTIVATED_TEAMS;

    dispatch(
      fetchReportsTeams({
        ...filter,
        ...valueToSearch,
        isInitialFetch: true,
        isSearchMode: true,
        show_only_deactivated: shouldShowDeactivateTeams,
        show_deactivation_info: shouldShowDeactivateTeams,
        onSuccess: fetchReportsTeamsOnSuccess,
      })
    );
  };

  /**
     * When on teams page, one team: navigate to team page
       When on teams page, 0 or 2+ team: show on the table
     */
  const handleSearchByDefault = ({
    valueToSearch,
  }: {
    valueToSearch: {
      ids: number[];
      search_text: string;
    };
  }) => {
    searchTeams({ valueToSearch });
  };

  /**
     * When on teams page, one team: navigate to team page
       When on teams page, 0 or 2+ team: show on the table
     */
  const handleSearchByTargetServiceId = (targetServiceId: number) => {
    dispatch(
      fetchIntegrations({
        targetServiceId,
        onSuccess: ({ response }) => {
          if (response.data.length > 0) {
            const { mosaicTeamId } = response.data[0];
            const valueToSearch = { ids: [mosaicTeamId], search_text: "" };
            searchTeams({ valueToSearch });
          } else {
            alert(`No team with integration ID ${targetServiceId} was found`);
          }
        },
      })
    );
  };

  const handleChangeSearchIdType = (event: React.ChangeEvent<any>) => {
    setSearchTeamStrategies(event.target.value);
  };

  const onEnterPress = debounce((key: string) => {
    const search_text = inputRef.current.value.trim();
    if (key === KEYBOARD_KEYS.ENTER && search_text) {
      const valueToSearch = getReportsTeamSearchValue(search_text);
      const { ids } = valueToSearch;

      if (ids.length === 0 || searchIdType === SearchTeamStrategies.ByTeamId) {
        handleSearchByDefault({ valueToSearch });
      } else {
        const targetServiceId = ids[0];
        handleSearchByTargetServiceId(targetServiceId);
      }
      inputRef.current.value = "";
    }
  }, 100);

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={2}>
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            {isOnRetoolPage ? (
              <></>
            ) : (
              <>
                <Grid item>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <Input
                      disabled={isFetchingTeams}
                      onKeyDown={(e) => {
                        const { key } = e;
                        onEnterPress(key);
                      }}
                      inputRef={inputRef}
                      placeholder={isFetchingTeams ? "Searching..." : "Search"}
                    />
                  </Search>
                </Grid>
                <FormControl margin="dense">
                  <Select
                    id="search-id-type"
                    value={searchIdType}
                    onChange={handleChangeSearchIdType}
                    style={{ width: "100%" }}
                  >
                    <MenuItem value={SearchTeamStrategies.ByTeamId}>
                      Team ID, Team Name, Member Email
                    </MenuItem>
                    <MenuItem value={SearchTeamStrategies.ByTargetServiceId}>
                      Integration ID
                    </MenuItem>
                  </Select>
                </FormControl>
                <Tooltip
                  title="If the team name contains mostly number, such as 123123123, put a ' in front of the input. (i.e: '123123123)"
                  style={{ margin: "0 10px 0 10px" }}
                >
                  <AlertCircle />
                </Tooltip>
              </>
            )}
            {SandboxSelector}
            <Grid item xs />
            <Grid item>
              <Switch
                onChange={toggleDevMode}
                checked={isDevMode}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <span style={{ display: isDevMode ? "inline" : "none" }}>
                <MessagesDropdown />
                <NotificationsDropdown />
                <LanguagesDropdown />
              </span>
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(AppBarComponent);
