import {
  MOSAIC_SANDBOX_TEAM_ID,
  LOWER_CASE_STAND_BY,
  INPUT_ERROR_MESSAGE,
  STAND_BY_TAG_ERROR_MESSAGE,
} from "@constants";

export const checkIsMosaicSandboxTeam = (teamId: number) => {
  return teamId === MOSAIC_SANDBOX_TEAM_ID;
};

export const checkUppercaseAndSpecialCharacters = (value: string) => {
  const lowercaseNumericRegex = /^[a-z0-9 ]+$/;
  return value !== "" && !lowercaseNumericRegex.test(value);
};

export const checkInputError = (input: string) => {
  // check if team name and tag inputs contain uppercase or special characters
  if (checkUppercaseAndSpecialCharacters(input)) {
    // show error if they do
    return INPUT_ERROR_MESSAGE;
    // check if the tag is stand by
  } else if (input === LOWER_CASE_STAND_BY) {
    // show error if it is
    return STAND_BY_TAG_ERROR_MESSAGE;
  }

  return "";
};
