import * as Moment from "moment";
import { extendMoment } from "moment-range";
import rangeRight from "lodash/rangeRight";
import { intervalsHash } from "@constants";

const moment = extendMoment(Moment);

/**
 * Utility function to convert a ISO string date to a string in the following format
 * Month / Date / Year
 * i.e: 12 / 03 / 2020
 */
export function convertISODateToRegularDateString(ISOString: string): string {
  if (!ISOString) return "";

  return new Date(ISOString).toLocaleString(undefined, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

/**
 * Generate an array of string date from startDate to endDate
 * @param startDate - Date Object
 * @param endDate - Date Object
 * @param interval - string, one of [day,year,month,week]
 * @returns {Array<string>} - i.e: [01/01/2021, 01/08/2021, 01/15/2021] etc...
 */
export const generateAllDatesWithinInterval = (
  startDate: Date,
  endDate: Date,
  interval: string
): Array<string> => {
  let fromDate = moment(startDate).utc();
  let toDate = moment(endDate).utc();
  let diff = toDate.diff(fromDate, interval as any);
  let dateArray = [];
  for (let i = 0; i <= diff; i++) {
    dateArray.push(
      moment(startDate)
        .utc()
        .add(i, interval as any)
        .format("MM/DD/YYYY")
    );
  }
  return dateArray;
};

/**
 * Generate a date string in the format of 'MM.DD.YYYY' (01.01.2021) based on the interval (day, week, month)
 * @param interval - One of ['Day', 'Week', 'Month']. If 'day', returns today's date
 * @returns {string}
 */
export const generateDateBeforeToday = (
  interval: string,
  count: number,
  format: string = "MM.DD.YYYY"
): string => {
  const lowerCaseInterval = interval.toLowerCase();

  if (count === 0) return moment().utc().format(format); // Today

  return moment()
    .utc()
    .subtract(count, lowerCaseInterval as moment.unitOfTime.DurationConstructor)
    .format(format);
};

/**
 * If the dateString given is falsy, returns an empty string
 * If is returns the locale version
 * @param dateString
 * @returns
 */
export const falsyCheckDate = (dateString?: string) =>
  dateString ? new Date(dateString).toLocaleString() : "";

/**
 * Generates a start date given an interval
 * For instance, selectedInterval = "Last Day", and today is "09/27/2021", generates "09/20/2021"
 * @param selectedInterval - One of day, month, year
 * @returns
 */
export const getStartDate = (selectedInterval: string, format?: string) => {
  const subtractCount = intervalsHash[selectedInterval].value;

  return generateDateBeforeToday("day", subtractCount, format);
};

/**
 * Take an array of date time string and returns the string
 * that is considered furthest in time
 * @param dateArray
 * @returns
 */
export const calculateLatestDate = (dateArray: Array<string>): string => {
  if (dateArray.length === 1) return dateArray[0];

  let latestDate = dateArray[0];

  for (let i = 1; i < dateArray.length; i++) {
    const currentDate = dateArray[i];
    const currDateMoment = moment(currentDate);

    if (currDateMoment.isAfter(moment(latestDate))) {
      latestDate = currentDate;
    }
  }
  return latestDate;
};

/**
 *  @param nativeDate: In the format of 'YYYY-MM-DD'
 *  @param expectedFormat: any Moment supported format
 *  @returns {string}: Time string in expectedFormat
 *
 */
export const convertNativeDateToExpectedFormat = (
  nativeDate: string,
  expectedFormat: string = "MM.DD.YYYY"
) => {
  return moment(nativeDate, "YYYY-MM-DD").format(expectedFormat);
};

/**
 *  @param range: Number of prvious months
 *  @param endMonth: In the format of 'MM/YYYY'
 *  @returns {Array<string>}: From earliest to latest, example: ['Oct 2021', 'Nov 2021', 'Dec 2021']
 *
 */
export const getSelectedMonthsArray = (range: number, endMonth: string) => {
  return rangeRight(range).map((n) => {
    return moment(endMonth, "MM-YYYY").subtract(n, "month").format("MMM YYYY");
  });
};

/**
 *  @param monthArray: An array of month-year string from earliest to latest, example: ['Oct 2021', 'Nov 2021', 'Dec 2021']
 *  @returns {Array<string>}: example: [{month: 'Oct', year: 2021}]
 *
 */
export const getMonthAndYearArray = (monthArray: Array<string>) => {
  return monthArray.map((n) => {
    const [month, year] = n.split(" ");
    return {
      month,
      year,
    };
  });
};

type MonthAndYear = {
  month: string;
  year: string;
};

type TeamUtilization = {
  [index: number]: {
    [index: string]: number;
  };
};

/**
 *  @param monthAndYearHashArray: An array of MonthAndYearHash from earliest to latest, example: [{month:'Oct',year:2021},{month: 'Nov',year:2021},{month: 'Dec',year:2021}]
 *  @returns {Array<number>}: percentage number [0,3.83,8.87, 9.22]
 *
 */
export const getUtilizationDataByMonth = (
  monthAndYearHashArray: Array<MonthAndYear>,
  teamUtilizationData: TeamUtilization
) => {
  return monthAndYearHashArray.map((monthYearObj) => {
    const yearKey = +monthYearObj.year;
    const monthKey = monthYearObj.month;
    const utilizationPercentage =
      (teamUtilizationData?.[yearKey]?.[monthKey] * 100).toFixed(2) || 0;
    return +utilizationPercentage;
  });
};

type utilizationByMonth = {
  [index: string]: number;
};

/**
 *  @param utiliazationMonthData: {Jan: 0, Feb: 0, Mar: 0, Apr: 0...}
 *  @returns {Number}: month in number form
 *
 */
export const getFirstNonZeroUtilizationMonth = (
  utiliazationMonthData: utilizationByMonth
) => {
  const monthArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  for (let i = 0; i < monthArray.length; i++) {
    const monthStr = monthArray[i];
    if (utiliazationMonthData[monthStr]) {
      return i + 1;
    }
  }
  return null;
};

type MinAndMaxMonthAndYear = {
  min: {
    year: number;
    month: number;
  };
  max: {
    year: number;
    month: number;
  };
};
/**
 *  @param yearArray: ['2017', '2018', '2019', '2020', '2021', '2022']
 *  @param teamUtilizationYearData: {2017: {Jan: 0, Feb: 0, Mar: 0, Apr: 0, May: 0, …}, 2018: {Jan: 0, Feb: 0, …}}
 * @returns {Object} Example: {min: {year: 2018, month: 9}, max: {year: 2022, month: 5}}
 *
 */
export const getFirstNonZeroUtilizationYearAndMonth = (
  yearArray: Array<string>,
  teamUtilizationYearData: TeamUtilization
): MinAndMaxMonthAndYear => {
  for (let i = 0; i < yearArray.length; i++) {
    const yearNum = +yearArray[i];
    const monthNum = getFirstNonZeroUtilizationMonth(
      teamUtilizationYearData[yearNum]
    );

    if (monthNum) {
      const yearAndMonth = {
        min: { year: yearNum, month: monthNum },
        max: { year: +moment().format("YYYY"), month: +moment().format("M") },
      };
      return yearAndMonth;
    }
  }
  return {
    min: { year: +moment().format("YYYY"), month: 1 },
    max: { year: +moment().format("YYYY"), month: +moment().format("M") },
  };
};

/**
 *  @param monthAndDateStr: {month:'11' , date: '25'}
 *  @returns {Number}: with 2 decimals
 *
 */
export const getDateDecimal = (monthAndDateStr: {
  monthLabel: string;
  month: string;
  date: string;
}) => {
  const dateNum = +monthAndDateStr.date;
  const monthNum = +monthAndDateStr.month;
  let decimal;
  switch (monthNum) {
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
      if (dateNum === 30) {
        decimal = 0.99;
      } else {
        decimal = +(dateNum / 31).toFixed(2);
      }
      break;
    case 4:
    case 6:
    case 9:
    case 11:
      if (dateNum === 30) {
        decimal = 0.99;
      } else {
        decimal = +(dateNum / 30).toFixed(2);
      }
      break;
    case 2:
      if (dateNum === 29) {
        decimal = 1;
      }
      decimal = +(dateNum / 28).toFixed(2);
      break;
    default:
      decimal = 0;
  }
  return decimal;
};

export const formatDate = (
  dateStr: Nullable<string> | undefined,
  format: string = "YYYY-MM-DD"
) => {
  if (!dateStr) return "";

  return moment(dateStr).utc().format(format);
};
